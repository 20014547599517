import { shareAccessLevel } from "consts";

export const getAccessTransId = (accessLevel: number): string | undefined => {
	const type = shareAccessLevel.find((item) => item.value === accessLevel);

	if (type) {
		return type.translationId;
	}

	return undefined;
};
