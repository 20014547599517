import { CircularProgress, Button } from "@mui/material";

const LoadingButton = (options: {
	isLoading: boolean;
	size: number;
	type?: "submit" | "reset" | "button" | undefined;
	label: string;
}) => {
	return (
		<>
			{options.isLoading ? (
				<CircularProgress
					size={options.size}
					style={{ margin: "0 auto" }}
				/>
			) : (
				<Button type={options.type}>{options.label}</Button>
			)}
		</>
	);
};

export default LoadingButton;
