import { FunctionComponent } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Tab, Tabs } from "@mui/material";
import { Mode } from "store/reducers/dishes/dishesReducer";

type TabNavigationProps = {
	value: Mode;
    onChange: (mode: Mode) => void;
};

const TabNavigation: FunctionComponent<TabNavigationProps & WrappedComponentProps> = ({ value, onChange, intl }) => {

    const handleChange = (_: React.SyntheticEvent, value: Mode) => onChange(value);

	return (
        <Tabs value={value} onChange={handleChange} centered>
            <Tab label={intl.formatMessage({ id: "app.suggested" })} value={Mode.Suggested} />
            <Tab label={intl.formatMessage({ id: "app.a_z" })} value={Mode.Dishes} />
            <Tab label={intl.formatMessage({ id: "app.favorites" })} value={Mode.Favorites} />
        </Tabs>
	);
};

export default injectIntl(TabNavigation);
