import { FunctionComponent, MouseEventHandler, ReactNode } from "react";
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	CircularProgress
} from "@mui/material";

type SimpleDialogProps = {
	open: boolean;
	onClose: MouseEventHandler<HTMLElement>;
	dialogContent: ReactNode;
	onConfirmButton: MouseEventHandler<HTMLElement>;
	confirmButtonText: ReactNode;
	cancelButtonText: ReactNode;
	isConfirmInProgress: Boolean;
};

const SimpleDialog: FunctionComponent<SimpleDialogProps> = ({
	open,
	onClose,
	dialogContent,
	onConfirmButton,
	confirmButtonText,
	cancelButtonText,
	isConfirmInProgress
}) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent>{dialogContent}</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>{cancelButtonText}</Button>
				{!isConfirmInProgress ? (
					<Button color="secondary" onClick={onConfirmButton}>
						{confirmButtonText}
					</Button>
				) : (
					<Button>
						<CircularProgress size={20} />
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default SimpleDialog;
