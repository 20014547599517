import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Tag } from "api/Tags/Types";
import DeleteDialog from "components/common/DeleteDialog";

interface TagDeleteDialogProps {
	tag: Tag;
	isOpen: boolean;
	deleteInProgress: boolean;
	onClose: () => void;
	onDelete: () => void;
}

const TagDeleteDialog: FunctionComponent<TagDeleteDialogProps> = ({
	tag,
	isOpen,
	deleteInProgress,
	onClose,
	onDelete
}) => {
	return (
		<DeleteDialog
			isOpen={isOpen}
			onClose={onClose}
			deleteInProgress={deleteInProgress}
			message={
				<FormattedMessage
					id="app.confirmTagDelete"
					values={{
						tagName: <strong>"{tag.name}"</strong>
					}}
				/>
			}
			onDelete={onDelete}
		/>
	);
};

export default TagDeleteDialog;
