import { IntlShape } from "react-intl"
import { StoreError } from "store/common"

export const getErrorMessage = (intl: IntlShape, error?: StoreError): string | undefined => {
    if (error) {
        if (error.message) {
            return intl.formatMessage({ id: error.message }, error.params);
        }
    
        return intl.formatMessage({ id: "app.somethingWentWrong" })
    }

    return undefined;
}