import {
  Workspace,
  WorkspaceCreate,
  Workspaces,
  WorkspaceUpdate,
} from "./Types";
import { AxiosInstance } from "axios";
import { ApiDataResponse, ApiResponse } from "../Types";
import { handle, handleData } from "../handlers";

export interface WorkspaceFilter {
  name?: string;
}

const workspaces_path = `/api/v1/workspaces`;

export class WorkspaceClient {
  axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async getWorkspaces(
    filter?: WorkspaceFilter
  ): Promise<ApiDataResponse<Workspaces>> {
    let url = workspaces_path;
    let query = "";

    if (filter) {
      if (filter.name) {
        query += "filter{name}=" + encodeURIComponent(filter.name);
      }
    }

    if (query) {
      url += "?" + query;
    }

    return await handleData<Workspaces>(() => this.axios.get(url));
  }

  async getWorkspace(workspaceId: number): Promise<ApiDataResponse<Workspace>> {
    return await handleData<Workspace>(() =>
      this.axios.get(workspaces_path + `/${workspaceId}`)
    );
  }

  async createWorkspace(
    workspace: WorkspaceCreate
  ): Promise<ApiDataResponse<Workspace>> {
    return await handleData<Workspace>(() =>
      this.axios.post(workspaces_path, workspace)
    );
  }

  async updateWorkspace(
    workspaceId: number,
    workspace: WorkspaceUpdate
  ): Promise<ApiDataResponse<Workspace>> {
    return await handleData<Workspace>(() =>
      this.axios.patch(workspaces_path + `/${workspaceId}`, workspace)
    );
  }

  async deleteWorkspace(workspaceId: number): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.delete(workspaces_path + `/${workspaceId}`)
    );
  }
}
