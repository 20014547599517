import {
	createAsyncThunk,
	createSlice
} from "@reduxjs/toolkit";
import { Dish, DishCreate, Dishes, DishUpdate } from "api/Dishes/Types";
import api from "api";
import { extractErrors, StoreError, StoreStatus } from "store/common";
import { RootState } from "store/store";
import { Rank, Ranking } from "api/Generator/Types";
import { selectRanking } from "../generator/generatorReducer";

export enum Mode {
    Suggested = "suggested",
    Favorites = "favorites",
    Dishes = "dishes"
}

interface DishesState {
	dishes: Dishes;
	fetchStatus: StoreStatus;
	fetchError?: StoreError;
	updateStatus: StoreStatus;
	updateError?: StoreError;
	deleteStatus: StoreStatus;
	deleteError?: StoreError;
	useDishStatus: StoreStatus;
	useDishError?: StoreError;
	dropDishStatus: StoreStatus;
	dropDishError?: StoreError;
	setFavoriteStatus: StoreStatus;
	setFavoriteError?: StoreError;
	unsetFavoriteStatus: StoreStatus;
	unsetFavoriteError?: StoreError;
}

const initialState: DishesState = {
	dishes: [],
	fetchStatus: StoreStatus.Idle,
  	fetchError: undefined,
	updateStatus: StoreStatus.Idle,
	updateError: undefined,
	deleteStatus: StoreStatus.Idle,
	deleteError: undefined,
	useDishStatus: StoreStatus.Idle,
	useDishError: undefined,
	dropDishStatus: StoreStatus.Idle,
	dropDishError: undefined,
	setFavoriteStatus: StoreStatus.Idle,
	setFavoriteError: undefined,
	unsetFavoriteStatus: StoreStatus.Idle,
	unsetFavoriteError: undefined
};

function sortDishes(dish: Dish, other: Dish): number {
	return dish.name.localeCompare(other.name);
}

function sortByRanking(ranking: Ranking): (dish: Dish, other: Dish) => number {
	const rankingIds = ranking.map((r: Rank) => r.id);
	return (dish: Dish, other: Dish) => rankingIds.indexOf(dish.id) - rankingIds.indexOf(other.id);
}

function filterByRanking(ranking: Ranking): (dish: Dish) => boolean {
	const rankingIds = ranking.map((r: Rank) => r.id);
	return (dish: Dish) => rankingIds.indexOf(dish.id) > -1;
}

export const selectDishes = (state: RootState, mode: Mode): Dishes => {
	if (state.dishes.dishes.length === 0) {
		return [];
	}

	var result = state.dishes.dishes.map((d: Dish) => d);
	if (mode === Mode.Favorites || mode === Mode.Dishes) {
		result.sort(sortDishes);
		if (mode === Mode.Favorites) {
			return result.filter((d: Dish) => d.favorite)
		}
		return result;
	}

	const ranking = selectRanking(state);
	if (!ranking) {
		return [];
	}

	result = result.filter(filterByRanking(ranking))
	result.sort(sortByRanking(ranking));

	return result;
}

export const selectDish = (state: RootState, dishId?: number): Dish | null =>
	state.dishes.dishes.find((dish: Dish) => dish.id === dishId);
export const selectDishesFetchError = (state: RootState): StoreError | undefined => state.dishes.fetchError;
export const selectDishesFetchStatus = (state: RootState): StoreStatus => state.dishes.fetchStatus;
export const selectDishUpdateError = (state: RootState): StoreError | undefined => state.dishes.updateError;
export const selectDishUpdateStatus = (state: RootState): StoreStatus => state.dishes.updateStatus;
export const selectDishDeleteError = (state: RootState): StoreError | undefined => state.dishes.deleteError;
export const selectDishDeleteStatus = (state: RootState): StoreStatus => state.dishes.deleteStatus;
export const selectUseDishError = (state: RootState): StoreError | undefined => state.dishes.useDishError;
export const selectUseDishStatus = (state: RootState): StoreStatus => state.dishes.useDishStatus;
export const selectDropDishError = (state: RootState): StoreError | undefined => state.dishes.dropDishError;
export const selectDropDishStatus = (state: RootState): StoreStatus => state.dishes.dropDishStatus;
export const selectDishSetFavoriteError = (state: RootState): StoreError | undefined => state.dishes.setFavoriteError;
export const selectDishSetFavoriteStatus = (state: RootState): StoreStatus => state.dishes.setFavoriteStatus;
export const selectDishUnsetFavoriteError = (state: RootState): StoreError | undefined => state.dishes.unsetFavoriteError;
export const selectDishUnsetFavoriteStatus = (state: RootState): StoreStatus => state.dishes.unsetFavoriteStatus;

function updateDishes(current: Dishes, update: Dishes): Dishes {
	update.forEach(dish => {
		const dishIndex = current.findIndex(d => d.id === dish.id);
		if (dishIndex >= 0) {
			current[dishIndex] = dish;
		} else {
			current.push(dish);
		}
	});

	return current;
}

export const dishesSlice = createSlice({
	name: "dishes",
	initialState,
	reducers: {
		resetUpdateDish: (state) => {
			state.updateStatus = StoreStatus.Idle;
			state.updateError = undefined;
		},
		resetUseDish: (state) => {
			state.useDishStatus = StoreStatus.Idle;
			state.useDishError = undefined;
		},
		resetDropDish: (state) => {
			state.dropDishStatus = StoreStatus.Idle;
			state.dropDishError = undefined;
		}
	},
	extraReducers(builder) {
	  builder
		.addCase(fetchDishes.pending, (state) => {
		  state.fetchStatus = StoreStatus.InProgress;
		})
		.addCase(fetchDishes.fulfilled, (state, action) => {
		  state.fetchStatus = StoreStatus.Succeeded;
		  if (action.payload) {
			state.dishes = updateDishes(state.dishes, action.payload);
		  }
		})
		.addCase(fetchDishes.rejected, (state, action) => {
		  state.fetchStatus = StoreStatus.Failed
		  state.fetchError = extractErrors(action);
		})
		.addCase(fetchDish.pending, (state) => {
		  state.fetchStatus = StoreStatus.InProgress;
		})
		.addCase(fetchDish.fulfilled, (state, action) => {
		  state.fetchStatus = StoreStatus.Succeeded;
		  if (action.payload) {
			if (action.payload) {
				state.dishes = updateDishes(state.dishes, [ action.payload ]);
			}
		  }
		})
		.addCase(fetchDish.rejected, (state, action) => {
		  state.fetchStatus = StoreStatus.Failed
		  state.fetchError = extractErrors(action);
		})
		.addCase(createDish.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(createDish.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			state.dishes = updateDishes(state.dishes, [ action.payload ]);
		  }
		})
		.addCase(createDish.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action);
		})
		.addCase(updateDish.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(updateDish.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
			if (action.payload) {
				state.dishes = updateDishes(state.dishes, [ action.payload ]);
			}
		})
		.addCase(updateDish.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action);
		})
		.addCase(deleteDish.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(deleteDish.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
		  	state.dishes = state.dishes.filter((dish) => dish.id !== action.payload);
		  }
		})
		.addCase(deleteDish.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action);
		})
		.addCase(setUseDish.pending, (state) => {
		  state.useDishStatus = StoreStatus.InProgress;
		})
		.addCase(setUseDish.fulfilled, (state) => {
		  state.useDishStatus = StoreStatus.Succeeded;
		})
		.addCase(setUseDish.rejected, (state, action) => {
		  state.useDishStatus = StoreStatus.Failed
		  state.useDishError = extractErrors(action);
		})
		.addCase(setDropDish.pending, (state) => {
		  state.dropDishStatus = StoreStatus.InProgress;
		})
		.addCase(setDropDish.fulfilled, (state) => {
		  state.dropDishStatus = StoreStatus.Succeeded;
		})
		.addCase(setDropDish.rejected, (state, action) => {
		  state.dropDishStatus = StoreStatus.Failed
		  state.dropDishError = extractErrors(action);
		})
		.addCase(setDishFavorite.pending, (state) => {
		  state.setFavoriteStatus = StoreStatus.InProgress;
		})
		.addCase(setDishFavorite.fulfilled, (state, action) => {
		  state.setFavoriteStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			const dish = state.dishes.find((dish) => dish.id === action.payload);
			if (dish) {
				dish.favorite = true;
				state.dishes = updateDishes(state.dishes, [ dish ]);
			}
		  }
		})
		.addCase(setDishFavorite.rejected, (state, action) => {
		  state.setFavoriteStatus = StoreStatus.Failed
		  state.setFavoriteError = extractErrors(action);
		})
		.addCase(unsetDishFavorite.pending, (state) => {
		  state.unsetFavoriteStatus = StoreStatus.InProgress;
		})
		.addCase(unsetDishFavorite.fulfilled, (state, action) => {
		  state.unsetFavoriteStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			const dish = state.dishes.find((dish) => dish.id === action.payload);
			if (dish) {
				dish.favorite = false;
				state.dishes = updateDishes(state.dishes, [ dish ]);
			}
		  }
		})
		.addCase(unsetDishFavorite.rejected, (state, action) => {
		  state.unsetFavoriteStatus = StoreStatus.Failed
		  state.unsetFavoriteError = extractErrors(action);
		})
	}
});

export const fetchDishes = createAsyncThunk('dishes/fetchDishes', async (workspaceId: number, { rejectWithValue }) => {
	try {
		const resp = await api.dishes.getDishes(workspaceId);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface DishParams {
	workspaceId: number;
	dishId: number;
}

export const fetchDish = createAsyncThunk('dishes/fetchDish', async (params: DishParams, { rejectWithValue }) => {
	try {
		const { workspaceId, dishId } = params;
		const resp = await api.dishes.getDish(workspaceId, dishId);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface CreateDishParams {
	workspaceId: number,
	data: DishCreate
}

export const createDish = createAsyncThunk('dishes/createDish', async (params: CreateDishParams, { rejectWithValue }) => {
	try {
		const { workspaceId, data } = params;
		const resp = await api.dishes.createDish(workspaceId, data);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface UpdateDishParams {
	workspaceId: number,
	dishId: number,
	data: DishUpdate
}

export const updateDish = createAsyncThunk('dishes/updateDish', async (params: UpdateDishParams, { rejectWithValue }) => {
	try {
		const { workspaceId, dishId, data } = params;
		const resp = await api.dishes.updateDish(workspaceId, dishId, data);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const deleteDish = createAsyncThunk('dishes/deleteDish', async (params: DishParams, { rejectWithValue }) => {
	const { workspaceId, dishId } = params;
	try {
		await api.dishes.deleteDish(workspaceId, dishId);
		return dishId;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const setUseDish = createAsyncThunk('dishes/setUseDish', async (params: DishParams, { rejectWithValue }) => {
	const { workspaceId, dishId } = params;
	try {
		await api.dishes.setUseDish(workspaceId, dishId);
		return dishId;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const setDropDish = createAsyncThunk('dishes/setDropDish', async (params: DishParams, { rejectWithValue }) => {
	const { workspaceId, dishId } = params;
	try {
		await api.dishes.setDropDish(workspaceId, dishId);
		return dishId;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const setDishFavorite = createAsyncThunk('dishes/setDishFavorite', async (params: DishParams, { rejectWithValue }) => {
	try {
		const { workspaceId, dishId } = params;
		await api.dishes.setFavoriteDish(workspaceId, dishId);
		return dishId;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const unsetDishFavorite = createAsyncThunk('dishes/unsetDishFavorite', async (params: DishParams, { rejectWithValue }) => {
	try {
		const { workspaceId, dishId } = params;
		await api.dishes.unsetFavoriteDish(workspaceId, dishId);
		return dishId;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const { resetUpdateDish, resetUseDish, resetDropDish } = dishesSlice.actions;

const dishesReducer = dishesSlice.reducer;

export default dishesReducer;
