import { Mode } from "store/reducers/dishes/dishesReducer";

export const pages = {
    main: {
        pattern: "/",
        url: () => "/"
    },
    profile: {
        pattern: "/profile",
        url: () => "/profile"
    },
    workspaces: {
        pattern: "/workspaces",
        url: () => "/workspaces"
    },
    workspaceCreate: {
        pattern: "/workspaces/create",
        url: () => "/workspaces/create"
    },
    login: {
        pattern: "/login",
        url: () => "/login"
    },
    register: {
        pattern: "/register",
        url: () => "/register" 
    },
    confirmCode: {
        pattern: "/confirm/:code",
        url: (code: string) => `/confirm/${code}`
    },
    resetPassword: {
        pattern: "/reset-password",
        url: () => "/reset-password" 
    },
    resetPasswordCode: {
        pattern: "/reset-password/:code",
        url: (code: string) => `/reset-password/${code}`
    },
    workspace: {
        pattern: "/:workspaceId/*",
        url: (workspaceId: number, mode?: Mode) => {
            let url = `/${workspaceId}`;
            if (mode)
                url += `/${mode}`;

            return url;
        },
        edit: {
            pattern: "edit",
            url: (workspaceId: number) => `/${workspaceId}/edit`
        },
        share: {
            pattern: "share",
            url: (workspaceId: number) => `/${workspaceId}/share`
        },
        tags: {
            pattern: "tags",
            url: (workspaceId: number) => `/${workspaceId}/tags`
        },
        tagCreate: {
            pattern: "tags/create",
            url: (workspaceId: number) => `/${workspaceId}/tags/create`
        },
        tagEdit: {
            pattern: "tags/:tagId/edit",
            url: (workspaceId: number, tagId: number) => `/${workspaceId}/tags/${tagId}/edit`
        },
        dishUse: {
            pattern: "dishes/use",
            url: (workspaceId: number) => `/${workspaceId}/dishes/use`
        },
        dishCreate: {
            pattern: "dishes/create",
            url: (workspaceId: number) => `/${workspaceId}/dishes/create`
        },
        dishEdit: {
            pattern: "dishes/:dishId/edit",
            url: (workspaceId: number, dishId: number) => `/${workspaceId}/dishes/${dishId}/edit`
        },
        dishView: {
            pattern: "dishes/:dishId",
            url: (workspaceId: number, dishId: number) => `/${workspaceId}/dishes/${dishId}`
        },
        suggestedSettings: {
            pattern: "suggested/settings",
            url: (workspaceId: number) => `/${workspaceId}/suggested/settings`
        },
        suggested: {
            pattern: "suggested",
            url: (workspaceId: number) => `/${workspaceId}/suggested`
        },
        dishes: {
            pattern: "dishes",
            url: (workspaceId: number) => `/${workspaceId}/dishes`
        },
        favorites: {
            pattern: "favorites",
            url: (workspaceId: number) => `/${workspaceId}/favorites`
        },
    }
}