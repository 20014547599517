import {
	createAsyncThunk,
	createSlice
} from "@reduxjs/toolkit";
import { EmailConfirm, ResetPassword, ResetPasswordRequest, User, UserRegisterData } from "api/Users/Types";
import api from "api";
import { extractErrors, StoreError, StoreStatus } from "store/common";
import { RootState } from "store/store";
import { Credentials } from "api/Types";


interface AuthState {
	isAuthenticated: boolean;
	loginStatus: StoreStatus;
	loginError?: StoreError;
	initializeResetPasswordFlowStatus: StoreStatus;
	initializeResetPasswordFlowError?: StoreError;
	resetPasswordStatus: StoreStatus;
	resetPasswordError?: StoreError;
	confirmEmailStatus: StoreStatus;
	confirmEmailError?: StoreError;
	registerUserStatus: StoreStatus;
	registerUserError?: StoreError;
	user: User | null;
	fetchUserStatus: StoreStatus;
	fetchUserError?: StoreError
}

const initialState: AuthState = {
	isAuthenticated: false,
	loginStatus: StoreStatus.Idle,
	loginError: undefined,
	initializeResetPasswordFlowStatus: StoreStatus.Idle,
	initializeResetPasswordFlowError: undefined,
	resetPasswordStatus: StoreStatus.Idle,
	resetPasswordError: undefined,
	confirmEmailStatus: StoreStatus.Idle,
	confirmEmailError: undefined,
	registerUserStatus: StoreStatus.Idle,
	registerUserError: undefined,
	user: null,
	fetchUserStatus: StoreStatus.Idle,
	fetchUserError: undefined,
};

export const selectUser = (state: RootState): User | null => state.auth.user;
export const selectIsAuthenticated = (state: RootState): boolean => state.auth.isAuthenticated;

export const selectLoginError = (state: RootState): StoreError | undefined => state.auth.loginError;
export const selectLoginStatus = (state: RootState): StoreStatus => state.auth.loginStatus;

export const selectInitializeResetPasswordFlowError = (state: RootState): StoreError | undefined => state.auth.initializeResetPasswordFlowError;
export const selectInitializeResetPasswordFlowStatus = (state: RootState): StoreStatus => state.auth.initializeResetPasswordFlowStatus;

export const selecteResetPasswordError = (state: RootState): StoreError | undefined => state.auth.resetPasswordError;
export const selectResetPasswordStatus = (state: RootState): StoreStatus => state.auth.resetPasswordStatus;

export const selectConfirmEmailError = (state: RootState): StoreError | undefined => state.auth.confirmEmailError;
export const selectConfirmEmailStatus = (state: RootState): StoreStatus => state.auth.confirmEmailError;

export const selectRegisterUserError = (state: RootState): StoreError | undefined => state.auth.registerUserError;
export const selectRegisterUserStatus = (state: RootState): StoreStatus => state.auth.registerUserStatus;

export const selectUserFetchError = (state: RootState): StoreError | undefined => state.auth.fetchUserError;
export const selectUserFetchStatus = (state: RootState): StoreStatus => state.auth.fetchUserStatus;

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		signIn: (state) => {
			state.isAuthenticated = true;
		},
		signOut: (state) => {
			state.isAuthenticated = false;
			state.user = null;
		}
	},
	extraReducers(builder) {
	  builder
		.addCase(login.pending, (state) => {
			state.loginStatus = StoreStatus.InProgress;
		})
		.addCase(login.fulfilled, (state, action) => {
			state.loginStatus = StoreStatus.Succeeded;
			state.user = action.payload || null;
		})
		.addCase(login.rejected, (state, action) => {
			state.loginStatus = StoreStatus.Failed
			state.loginError = extractErrors(action)
		})
		.addCase(fetchUser.pending, (state) => {
		  state.fetchUserStatus = StoreStatus.InProgress;
		})
		.addCase(fetchUser.fulfilled, (state, action) => {
		  state.fetchUserStatus = StoreStatus.Succeeded;
			state.user = action.payload || null;
		})
		.addCase(fetchUser.rejected, (state, action) => {
		  state.fetchUserStatus = StoreStatus.Failed
		  state.fetchUserError = extractErrors(action)
		})
		.addCase(initializeResetPasswordFlow.pending, (state) => {
		  state.initializeResetPasswordFlowStatus = StoreStatus.InProgress;
		})
		.addCase(initializeResetPasswordFlow.fulfilled, (state) => {
		  state.initializeResetPasswordFlowStatus = StoreStatus.Succeeded;
		})
		.addCase(initializeResetPasswordFlow.rejected, (state, action) => {
		  state.initializeResetPasswordFlowStatus = StoreStatus.Failed
		  state.initializeResetPasswordFlowError = extractErrors(action)
		})
		.addCase(resetPassword.pending, (state) => {
		  state.resetPasswordStatus = StoreStatus.InProgress;
		})
		.addCase(resetPassword.fulfilled, (state) => {
		  state.resetPasswordStatus = StoreStatus.Succeeded;
		})
		.addCase(resetPassword.rejected, (state, action) => {
		  state.resetPasswordStatus = StoreStatus.Failed
		  state.resetPasswordError = extractErrors(action)
		})
		.addCase(confirmEmail.pending, (state) => {
		  state.confirmEmailStatus = StoreStatus.InProgress;
		})
		.addCase(confirmEmail.fulfilled, (state) => {
		  state.confirmEmailStatus = StoreStatus.Succeeded;
		})
		.addCase(confirmEmail.rejected, (state, action) => {
		  state.confirmEmailStatus = StoreStatus.Failed
		  state.confirmEmailError = extractErrors(action)
		})
		.addCase(registerUser.pending, (state) => {
		  state.registerUserStatus = StoreStatus.InProgress;
		})
		.addCase(registerUser.fulfilled, (state) => {
		  state.registerUserStatus = StoreStatus.Succeeded;
		})
		.addCase(registerUser.rejected, (state, action) => {
		  state.registerUserStatus = StoreStatus.Failed
		  state.registerUserError = extractErrors(action)
		})
	}
});

export const login = createAsyncThunk('auth/login', async (payload: Credentials, { rejectWithValue }) => {
	try {
		await api.login(payload);
		const resp = await api.users.getProfile();
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const initializeResetPasswordFlow = createAsyncThunk('auth/initializeResetPasswordFlow', async (payload: ResetPasswordRequest, { rejectWithValue }) => {
	try {
		return await api.users.requestResetPassword(payload);
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const resetPassword = createAsyncThunk('auth/resetPassword', async (payload: ResetPassword, { rejectWithValue }) => {
	try {
		return await api.users.resetPassword(payload);
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const confirmEmail = createAsyncThunk('auth/confirmEmail', async (payload: EmailConfirm, { rejectWithValue }) => {
	try {
		return await api.users.confirmEmail(payload);
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const registerUser = createAsyncThunk('auth/register', async (payload: UserRegisterData, { rejectWithValue }) => {
	try {
		return await api.users.register(payload);
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const fetchUser = createAsyncThunk('auth/fetchUser', async (_, { rejectWithValue }) => {
	try {
		const resp = await api.users.getProfile();
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

const authReducer = authSlice.reducer;

export const { signIn, signOut } = authSlice.actions;

export default authReducer;
