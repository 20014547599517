import {
	createAsyncThunk,
	createSlice
} from "@reduxjs/toolkit";
import { defineLanguage, Language } from "helpers/language";
import { RootState } from "store/store";
import { languageStorageKey } from "consts";
import { StoreError, StoreStatus } from "store/common";

interface LanguageState {
	language: Language;
	status: StoreStatus;
	error?: StoreError;
}

const initialState: LanguageState = {
	language: defineLanguage(),
	status: StoreStatus.Idle,
	error: undefined,
};

export const selectLanguage = (state: RootState): Language => state.language.language;

export const languageSlice = createSlice({
	name: "language",
	initialState,
	reducers: {},
	extraReducers(builder) {
	  builder
		.addCase(changeLanguage.pending, (state) => {
		  state.status = StoreStatus.InProgress;
		})
		.addCase(changeLanguage.fulfilled, (state, action) => {
		  state.status = StoreStatus.Succeeded;
		  if (action.payload) {
			state.language = action.payload;
		  }
		})
		.addCase(changeLanguage.rejected, (state, action) => {
		  state.status = StoreStatus.Failed
		  state.error = { message: action.error.message };
		})
	}
});

export const changeLanguage = createAsyncThunk('language/changeLanguage', async (locale: string) => {
	localStorage.setItem(languageStorageKey, locale);
	return defineLanguage();
});

const languageReducer = languageSlice.reducer;

export default languageReducer;
