import { useEffect } from "react";
import { ErrorOption } from "react-hook-form";
import { IntlShape } from "react-intl";
import { FieldErrors, StoreError } from "store/common";

export type FieldMap = {
    [key: string]: string,
}

export function useErrors(
    storeError: StoreError | undefined,
    setError: (name: string, error: ErrorOption, options?: { shouldFocus: boolean; } | undefined) => void,
    intl: IntlShape,
    fieldMap?: FieldMap) {

    useEffect(() => {
        if (storeError?.fieldErrors) {
            for (const field in storeError.fieldErrors) {
                setError(
                    getControlName(fieldMap, field),
                    { 
                        type: 'custom',
                        message: buildMessage(storeError.fieldErrors, field, intl)
                    }
                )
            }
        }
    }, [storeError, fieldMap, intl, setError]);
}

function buildMessage(fieldErrors: FieldErrors, field: string, intl: IntlShape): string | undefined {
    return fieldErrors[field]
        .map(m => intl.formatMessage({ id: m.error }, m.params))
        .join(", ");
}

function getControlName(fieldMap: FieldMap | undefined, field: string): string {
    return fieldMap && field in fieldMap ? fieldMap[field] : field;
}
