import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { IntlShape } from "react-intl";

const defaultErrorMap: Record<string, string> = {
    'required': "app.emptyField"
}

export function getFieldErrorMessage(
    fieldError: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined, 
    intl: IntlShape,
    errorMap?: Record<string, string>,    
    ): string | undefined {
    if (fieldError) {
        if (fieldError.type === "custom") {
            return fieldError.message?.toString();
        }

        if (fieldError.type) {
            if (errorMap && fieldError.type.toString() in errorMap) {
                return intl.formatMessage({ id: errorMap[fieldError.type.toString()] });
            }

            if (fieldError.type.toString() in defaultErrorMap) {
                return intl.formatMessage({ id: defaultErrorMap[fieldError.type.toString()] });
            }

            return intl.formatMessage({ id: fieldError.type.toString() });
        }
    }

    return undefined;
}