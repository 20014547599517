import { CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";
import { pages } from "pages";
import { FunctionComponent, useEffect } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Navigate } from "react-router";
import { toast } from "react-toastify";
import { StoreStatus } from "store/common";
import { fetchWorkspaces, selectPersonalWorkspace, selectWorkspacesFetchError, selectWorkspacesFetchStatus } from "store/reducers/workspaces/workspacesReducer";

const MainPage: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const dispatch = useAppDispatch();
	
	const personalWorkspace = useAppSelector(selectPersonalWorkspace);
	const workspacesStatus = useAppSelector(selectWorkspacesFetchStatus);
	const workspacesError = useAppSelector(selectWorkspacesFetchError);

	useEffect(() => {
		if (workspacesStatus === StoreStatus.Idle) {
			dispatch(fetchWorkspaces());
		}
	}, [workspacesStatus, dispatch]);

	useEffect(() => {
		if (workspacesStatus === StoreStatus.Failed) {
			if (workspacesError) {
				toast.error(workspacesError.message);
				console.error(workspacesError.message);
			} else {
				toast.error(`${intl}`);
			}
		}
	}, [workspacesStatus, dispatch]);

	if (workspacesStatus === StoreStatus.InProgress) {
		return <CircularProgress />;
	}

	if (personalWorkspace?.id) {
		return <Navigate to={pages.workspace.url(personalWorkspace?.id)} />
	}

	return <></>;
}

export default injectIntl(MainPage);