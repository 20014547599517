import { ImpactValue, TimeEffort } from "../Types";

export type Rank = {
  id: number;
  name: string;
  score: number;
};

export type Ranking = Rank[];

export const DefaultGenerateQuery: GenerateQuery = {
  time_effort: TimeEffort.Long,
  season: ImpactValue.Ignore,
  tags: [],
};

export interface GenerateQuery {
  time_effort: TimeEffort;
  season: ImpactValue;
  kilocalories?: number;
  tags: TagValue[];
}

export interface TagValue {
  id: number;
  value: ImpactValue;
}
