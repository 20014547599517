import {
	configureStore,
	combineReducers,
	Reducer,
	Action
} from "@reduxjs/toolkit";
import authReducer, { signOut } from "./reducers/auth/authReducer";
import workspacesReducer from "./reducers/workspaces/workspacesReducer";
import tagsReducer from "./reducers/tags/tagsReducer";
import dishesReducer from "./reducers/dishes/dishesReducer";
import languageReducer from "./reducers/language/languageReducer";
import shareReducer from "./reducers/shares/shareReducer";
import shareLinkReducer from "./reducers/shareLinks/shareLinkReducer";
import generatorReducer from "./reducers/generator/generatorReducer";
import layoutReducer from "./reducers/layout/layoutReducer";

export type RootState = ReturnType<typeof store.getState>;

const shouldEnableDevTools =
	process.env.NODE_ENV !== "production" &&
	typeof window !== "undefined" &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const rootReducer = combineReducers({
	auth: authReducer,
	layout: layoutReducer,
	workspaces: workspacesReducer,
	tags: tagsReducer,
	dishes: dishesReducer,
	language: languageReducer,
	share: shareReducer,
	shareLink: shareLinkReducer,
	generator: generatorReducer,
});

const appReducer: Reducer = (state: RootState, action: Action<string>) => {
	if (signOut.match(action)) {
		state = undefined;
	}

	return rootReducer(state, action);
};

const store = configureStore({
	reducer: appReducer,
	devTools: !!shouldEnableDevTools
});

export type AppDispatch = typeof store.dispatch;

export default store;
