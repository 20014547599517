import { FunctionComponent, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import Logo from "components/common/Logo/Logo";
import { pages } from "pages";
import { useAppDispatch, useAppSelector } from "hooks";
import { confirmEmail, selectConfirmEmailError, selectConfirmEmailStatus } from "store/reducers/auth/authReducer";
import ContentError from "components/common/ContentPage/ContentError";
import { getErrorMessage } from "helpers/api";
import { StoreStatus } from "store/common";

import "./register.css";


type ConfirmParams = {
	code?: string;
};

const Confirm: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	
	const { code } = useParams<ConfirmParams>();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const confirmEmailError = useAppSelector(selectConfirmEmailError);
	const confirmEmailStatus = useAppSelector(selectConfirmEmailStatus);

	useEffect(() => {
		if (code && confirmEmailStatus === StoreStatus.Idle) {
			dispatch(confirmEmail({ code }));
		}
	}, [code]);

	useEffect(() => {
		if (confirmEmailStatus === StoreStatus.Succeeded) {
			toast.success(
				`🙌 ${intl.formatMessage({ id: "app.successConfirm" })}`
			);
			navigate(pages.login.url());
		}
	})

	const errorMessage = !code
		? intl.formatMessage({ id: "app.missingConfirmationCode" })
		: getErrorMessage(intl, confirmEmailError);
	return (
		<div className="confirmation-wrapper">
			<div className="logo-wrapper">
				<Logo />
			</div>

			<ContentError error={errorMessage} />

			{confirmEmailStatus === StoreStatus.InProgress && (
				<div style={{ marginTop: 20, textAlign: "center" }}>
					<CircularProgress />
				</div>
			)}
		</div>
	);
};

export default injectIntl(Confirm);
