import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { ButtonsWrapper } from "theme/styledComponents";
import {
	TextField,
	Button,
	FormControl,
	FormLabel,
	FormGroup,
	FormControlLabel,
	Checkbox,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Autocomplete
} from "@mui/material";
import ContentPage from "components/common/ContentPage/ContentPage";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { createDish, fetchDish, resetUpdateDish, selectDish, selectDishesFetchError, selectDishesFetchStatus, selectDishUpdateError, selectDishUpdateStatus, updateDish } from "store/reducers/dishes/dishesReducer";
import { fetchTags, selectTags, selectTagsFetchStatus } from "store/reducers/tags/tagsReducer";
import { Dish } from "api/Dishes/Types";
import { Tag, Tags } from "api/Tags/Types";
import { pages } from "pages";
import { StoreStatus } from "store/common";
import SubmitButton from "components/common/SubmitButton";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { getFieldErrorMessage } from "helpers/errors";
import { getErrorMessage } from "helpers/api";
import ContentError from "components/common/ContentPage/ContentError";
import { getNumberParam } from "helpers/views";


type DishFormParams = {
	dishId: string;
}

type DishFormSubmit = (payload: FieldValues) => void;

const DishForm: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const params = useParams<DishFormParams>();
	const [ dishId, paramError ] = getNumberParam(params.dishId, "dishId", intl);

	const closeLink = pages.workspace.url(workspaceId);

	const [checkboxesValues, setCheckboxesValues] = useState({
		winter: false,
		summer: false,
		autumn: false,
		spring: false
	});

	const tags = useAppSelector(selectTags);
	const fetchTagsStatus = useAppSelector(selectTagsFetchStatus);

	const dish = useAppSelector((state) => selectDish(state, dishId));
	const fetchStatus = useAppSelector(selectDishesFetchStatus);
	const fetchError = useAppSelector(selectDishesFetchError);
	const updateStatus = useAppSelector(selectDishUpdateStatus);
	const updateError = useAppSelector(selectDishUpdateError);

	useEffect(() => {
		if (dishId && fetchStatus === StoreStatus.Idle) {
			dispatch(fetchDish({
				workspaceId,
				dishId: dishId 
			}))
		}
	}, [ workspaceId, dishId, fetchStatus, dispatch ])

	const [selectedTags, setSelectedTags] = useState<Tags>([]);
	const {
		register,
		handleSubmit,
		setValue,
		control,
		formState: { errors }
	} = useForm({ shouldUnregister: true });

	const onSubmit: DishFormSubmit = async ({
		dishName,
		recipe,
		timeEffort,
		kilocalories
	}) => {
		const { spring, summer, autumn, winter } = checkboxesValues;

		const data = {
			name: dishName,
			time_effort: timeEffort,
			recipe,
			spring,
			summer,
			autumn,
			winter,
			kilocalories: Number(kilocalories),
			tags: selectedTags.map((tag) => tag.id)
		};

		if (dishId) {
			dispatch(updateDish({ workspaceId, dishId, data }));
		} else {
			dispatch(createDish({ workspaceId, data }));
		}
	};

	useEffect(() => {
		if (updateStatus === StoreStatus.Succeeded) {
			dispatch(resetUpdateDish());
			navigate(closeLink);
		}
	}, [ updateStatus, navigate, closeLink, dispatch ]);

	const setCheckboxValue = (e: ChangeEvent<HTMLInputElement>) => {
		setCheckboxesValues({
			...checkboxesValues,
			[e.target.name]: e.target.checked
		});
	};

	const setFormValues = (data: Dish) => {
		setValue("dishName", data.name);
		setValue("timeEffort", data.time_effort);
		setValue("recipe", data.recipe);
		setValue("kilocalories", data.kilocalories);
		setCheckboxesValues({
			...checkboxesValues,
			winter: data.winter,
			summer: data.summer,
			autumn: data.autumn,
			spring: data.spring
		});
		setSelectedTags(data.tags || []);
	};

	useEffect(() => {
		if (dish && fetchStatus === StoreStatus.Succeeded) {
			setFormValues(dish);
		}
	}, [dish, fetchStatus]);

	useEffect(() => {
		if (fetchTagsStatus === StoreStatus.Idle && workspaceId) {
			dispatch(fetchTags(workspaceId));
		}
	}, [fetchTagsStatus, workspaceId]);

	return (
		<ContentPage
			title={
				dishId
					? intl.formatMessage({ id: "app.editDish" })
					: intl.formatMessage({ id: "app.addDish" })
			}
			closeLink={closeLink}
			loading={fetchStatus === StoreStatus.InProgress && !!workspaceId}
			error={paramError || fetchError?.message}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<TextField
					{...register("dishName", { required: true })}
					fullWidth
					label={intl.formatMessage({ id: "app.name" })}
					helperText={getFieldErrorMessage(errors.dishName, intl, {
						"duplicated": "app.dishAlreadyExist"
					})}
					error={!!errors.dishName}
					style={{ marginTop: 10 }}
				/>
				<FormControl component="fieldset" fullWidth>
					<FormLabel
						style={{ textAlign: "center", marginTop: "0.8rem" }}
					>
						{intl.formatMessage({ id: "app.season" })}
					</FormLabel>
					<FormGroup
						aria-label="position"
						row
						style={{ margin: "0 auto" }}
					>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									name="spring"
									checked={checkboxesValues.spring || false}
									onChange={setCheckboxValue}
								/>
							}
							label={intl.formatMessage({ id: "app.spring" })}
						/>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									name="summer"
									checked={checkboxesValues.summer || false}
									onChange={setCheckboxValue}
								/>
							}
							label={intl.formatMessage({ id: "app.summer" })}
						/>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									name="autumn"
									checked={checkboxesValues.autumn || false}
									onChange={setCheckboxValue}
								/>
							}
							label={intl.formatMessage({ id: "app.autumn" })}
						/>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									name="winter"
									checked={checkboxesValues.winter || false}
									onChange={setCheckboxValue}
								/>
							}
							label={intl.formatMessage({ id: "app.winter" })}
						/>
					</FormGroup>
				</FormControl>
				<FormControl
					fullWidth
					error={!!errors.timeEffort}
					style={{ marginTop: "0.8rem" }}
				>
					<InputLabel>
						{intl.formatMessage({ id: "app.timeEffort" })}
					</InputLabel>
					<Controller
						render={({ field }) => (
							<Select
								label={intl.formatMessage({
									id: "app.timeEffort"
								})}
								style={{ textAlign: "left" }}
								{...field}
							>
								<MenuItem value={0}>
									{intl.formatMessage({
										id: "app.short"
									})}
								</MenuItem>
								<MenuItem value={1}>
									{intl.formatMessage({
										id: "app.medium"
									})}
								</MenuItem>
								<MenuItem value={2}>
									{intl.formatMessage({ id: "app.long" })}
								</MenuItem>
							</Select>
						)}
						name="timeEffort"
						control={control}
						rules={{ required: true }}
						defaultValue=""
					/>
					<FormHelperText>
						{errors.timeEffort &&
							intl.formatMessage({ id: "app.emptyField" })}
					</FormHelperText>
				</FormControl>
				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.recipe" })}
					{...register("recipe")}
					style={{ marginTop: "0.8rem" }}
				/>
				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.kilocalories" })}
					{...register("kilocalories")}
					style={{ marginTop: "0.8rem" }}
					type="number"
					inputProps={{ min: "0", max: "10000", step: "1" }}
					helperText={getFieldErrorMessage(errors.kilocalories, intl)}
					error={!!errors.password}
				/>
				<FormControl component="fieldset" fullWidth>
					<FormLabel
						style={{
							textAlign: "center",
							marginTop: "0.8rem"
						}}
					>
						{intl.formatMessage({ id: "app.tags" })}
					</FormLabel>
					<Autocomplete
						multiple
						value={selectedTags}
						options={tags || []}
						noOptionsText={intl.formatMessage({
							id: "app.noOptions"
						})}
						getOptionLabel={(option: Tag) => option.name}
						isOptionEqualToValue={(option, value) =>
							option.id === value.id
						}
						groupBy={(option) => option.group}
						onChange={(_, value: Tags) =>
							setSelectedTags(value)
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label={intl.formatMessage({
									id: "app.chooseTag"
								})}
								fullWidth
							/>
						)}
					/>
				</FormControl>
				<ContentError error={getErrorMessage(intl, updateError)} />
				<ButtonsWrapper>
					<Button
						color="secondary"
						variant="contained"
						component={Link}
						to={closeLink}
						style={{ marginRight: 10 }}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<SubmitButton
						inProgress={fetchStatus === StoreStatus.InProgress}
						caption={intl.formatMessage({ id: "app.save" })}
					/>
				</ButtonsWrapper>
			</form>
		</ContentPage>
	);
};

export default injectIntl(DishForm);
