import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContentPage from "components/common/ContentPage/ContentPage";
import { Dish } from "api/Dishes/Types";
import { useAppDispatch, useAppSelector } from "hooks";
import { toast } from "react-toastify";
import {
    deleteDish,
    fetchDishes,
    Mode,
    selectDishes,
    selectDishesFetchStatus,
    setUseDish,
    setDropDish,
    unsetDishFavorite,
    setDishFavorite,
    selectUseDishStatus,
    selectDropDishStatus,
    selectDropDishError,
    selectUseDishError,
    resetUseDish,
    resetDropDish
} from "store/reducers/dishes/dishesReducer";
import DishList from "components/common/DishList/DishList";
import TabNavigation from "../TabNavigation";
import { pages } from "pages";
import { StoreStatus } from "store/common";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { fetchRanking, selectQuery, selectRankingFetchError, selectRankingFetchStatus } from "store/reducers/generator/generatorReducer";
import { getErrorMessage } from "helpers/api";

type AZDishesProps = {
	workspaceId: number;
    mode: Mode
};

const Dishes: FunctionComponent<AZDishesProps & WrappedComponentProps> = ({ workspaceId, mode, intl }) => {
	const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const dishes = useAppSelector(state => selectDishes(state, mode));
    const fetchStatus = useAppSelector(selectDishesFetchStatus);

    const query = useAppSelector(selectQuery);
    
    const fetchRankingStatus = useAppSelector(selectRankingFetchStatus);
    const fetchRankingError = useAppSelector(selectRankingFetchError);

    const useDishStatus = useAppSelector(selectUseDishStatus);
    const useDishError = useAppSelector(selectUseDishError);

    const dropDishStatus = useAppSelector(selectDropDishStatus);
    const dropDishError = useAppSelector(selectDropDishError);

    const onDeleteDishClick = async (dish: Dish) => {
        dispatch(deleteDish({
            workspaceId,
            dishId: dish.id
        }));
    }

    const onUseDishClick = async (dish: Dish) => {
        dispatch(setUseDish({ workspaceId, dishId: dish.id }));
        navigate(pages.workspace.dishView.url(workspaceId, dish.id));
    }

    const onDropDishClick = async (dish: Dish) => {
        dispatch(setDropDish({ workspaceId, dishId: dish.id }))
    };

    useEffect(() => {
        if (fetchStatus === StoreStatus.Idle) {
            dispatch(fetchDishes(workspaceId));
        }

        if (
            fetchRankingStatus === StoreStatus.Idle || 
            (
                (useDishStatus === StoreStatus.Succeeded || dropDishStatus === StoreStatus.Succeeded)
                && mode === Mode.Suggested
            )
        ) {
            resetUseDish();
            resetDropDish();
            dispatch(fetchRanking({ workspaceId, query }));
        }
    }, [ fetchStatus, fetchRankingStatus, dropDishStatus, useDishStatus, mode, dispatch, workspaceId, query ]);

    useEffect(() => {
		if (useDishStatus === StoreStatus.Failed) {
            if (useDishError) {
                toast.error(intl.formatMessage({ id: useDishError.message }));
            } else {
                toast.error(
                    `🙌 ${intl.formatMessage({
                        id: "app.useDishFailed"
                    })}`
                );
            }
		}
	}, [useDishStatus, useDishError, intl, navigate ]);

    useEffect(() => {
		if (dropDishStatus === StoreStatus.Failed) {
			if (dropDishError) {
                toast.error(intl.formatMessage({ id: dropDishError.message }));
            } else {
                toast.success(
                    `🙌 ${intl.formatMessage({
                        id: "app.dropDishFailed"
                    })}`
                );
            }
		}
	}, [dropDishStatus, dropDishError, intl, navigate ]);

    const onEditDishClick = async (dish: Dish) => {
        navigate(pages.workspace.dishEdit.url(workspaceId, dish.id));
    }

    const onToggleFavoriteClick = async (dish: Dish) => {
        if (dish.favorite) {
			dispatch(unsetDishFavorite({ workspaceId, dishId: dish.id }));
		} else {
			dispatch(setDishFavorite({ workspaceId, dishId: dish.id }));
		}
    }
    
    const viewDish = async (dish: Dish) => {
        navigate(pages.workspace.dishView.url(workspaceId, dish.id));
    }

    const onNavChange = (mode: Mode) => {
        navigate(pages.workspace.url(workspaceId, mode));
    };

    const onAddDishClick = () => {
        navigate(pages.workspace.dishCreate.url(workspaceId));
    };

	return (
        <>
            <TabNavigation value={mode} onChange={onNavChange} />
            <ContentPage
                loading={fetchStatus === StoreStatus.InProgress}
                filterLink={mode !== Mode.Suggested ? "filter" : undefined}
                settingsLink={mode === Mode.Suggested ? "settings" : undefined}
                error={getErrorMessage(intl, fetchRankingError)}
            >
                {dishes && (
                    <DishList
                        dishes={dishes}
                        onDishDeleteClick={onDeleteDishClick}
                        onDishUseClick={onUseDishClick}
                        onDishDropClick={onDropDishClick}
                        onDishEditClick={onEditDishClick}
                        onDishFavoriteClick={onToggleFavoriteClick}
                        onDishNameClick={viewDish}
                        onAddDishClick={onAddDishClick}
                    />)}
            </ContentPage>
        </>
	);
};

export default injectIntl(Dishes);
