import { styled } from "@mui/material/styles";


type ContentWrapperStyleProps = {
	marginLeft: number,
	marginBottom: number,
};

const ContentWrapper = styled("div")<ContentWrapperStyleProps>(
	({ theme, marginLeft, marginBottom }) => ({
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		marginLeft: marginLeft,
		marginBottom: marginBottom,
		// ^^ workaround due to limited time mvp approach (for too short scroll on mobile),
		// this margin should be added dynamically and depends on bottomNavigation height
	})
);

export default ContentWrapper;
