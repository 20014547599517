import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	injectIntl,
	WrappedComponentProps
} from "react-intl";
import ListHeaderSection from "components/common/ListHeaderSection/ListHeaderSection";
import {
	selectTagDeleteError,
	selectTagDeleteStatus,
	selectTagsFetchStatus,
	selectTags,
	selectTagsFetchError,
	deleteTag,
	fetchTags
} from "store/reducers/tags/tagsReducer";
import { useAppDispatch, useAppSelector } from "hooks";
import { pages } from "pages";
import { StoreStatus } from "store/common";
import TagDeleteDialog from "./TagDeleteDialog";
import ContentLoader from "components/common/ContentPage/ContentLoader";
import { Tag } from "api/Tags/Types";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";

const TagPage: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({
	workspaceId,
	intl
}) => {
	const dispatch = useAppDispatch();

	const [isDialogOpened, setDialogOpened] = useState(false);
	const [tagToDelete, setTagToDelete] = useState<Tag | null>(null);
	
	const tags = useAppSelector(selectTags);
	const fetchStatus = useAppSelector(selectTagsFetchStatus);
	const fetchError = useAppSelector(selectTagsFetchError);

	const deleteStatus = useAppSelector(selectTagDeleteStatus);
	const deleteError = useAppSelector(selectTagDeleteError);

	const onConfirmDeleteTag = async () => {
		if (tagToDelete) {
			dispatch(deleteTag({
				workspaceId,
				tagId: tagToDelete.id,
			}));
		}
	};

	useEffect(() => {
        if (fetchStatus === StoreStatus.Idle) {
            dispatch(fetchTags(workspaceId));
        }
    }, [ dispatch, workspaceId, fetchStatus ]);

	useEffect(() => {
		if (deleteStatus === StoreStatus.Succeeded || deleteStatus === StoreStatus.Failed) {
			closeDialog();
		}
		if (deleteStatus === StoreStatus.Failed && deleteError) {
			toast.error(deleteError.message);
		}
	}, [ deleteStatus, deleteError ])

	const closeDialog = () => {
		setDialogOpened(false);
	};

	return (
		<ContentLoader
			loading={fetchStatus === StoreStatus.InProgress} 
			error={fetchError?.message}
		>
			<ListHeaderSection
				pageTitle={intl.formatMessage({ id: "app.tags" }) + ":"}
				btnHref={pages.workspace.tagCreate.url(workspaceId)}
				btnText={intl.formatMessage({ id: "app.create" })}
			/>
			{tags.length > 0 ? 
				<List>
					{tags.map((tag: Tag) => {
						return (
							<ListItem key={tag.id} divider>
								<ListItemText
									primary={tag.name}
									secondary={tag.group}
								/>

								<ListItemSecondaryAction>
									<IconButton
										component={Link}
										to={pages.workspace.tagEdit.url(workspaceId, tag.id)}
									>
										<EditIcon color="primary" />
									</IconButton>
									<IconButton
										onClick={() => {
											setTagToDelete(tag);
											setDialogOpened(true);
										}}
									>
										<DeleteIcon color="secondary" />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</List>
			: <Typography variant="h5">
				{intl.formatMessage({ id: "app.emptyTags" })}
			</Typography>}
			{tagToDelete !== null && (
			<TagDeleteDialog
				deleteInProgress={deleteStatus === StoreStatus.InProgress}
				isOpen={isDialogOpened}
				tag={tagToDelete}
				onClose={closeDialog}
				onDelete={onConfirmDeleteTag}
			/>)}
		</ContentLoader>
	);
};

export default injectIntl(TagPage);
