import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { FunctionComponent, ReactElement } from "react";

type HideOnScrollProps = {
	direction?: "left" | "right" | "up" | "down";
	children: ReactElement<any, any>;
};

const HideOnScroll: FunctionComponent<HideOnScrollProps> = ({
	children,
	direction
}) => {
	const trigger = useScrollTrigger();

	return (
		<Slide appear={false} direction={direction} in={!trigger} >
			{children}
		</Slide>
	);
};

export default HideOnScroll;
