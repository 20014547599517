import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { toast } from "react-toastify";
import Logo from "components/common/Logo/Logo";
import LoadingButton from "components/common/LoadingButton/LoadingButton";
import TextWithLink from "components/common/TextWithLink/TextWithLink";

import "./login.css";

import { ResetPasswordRequest } from "api/Users/Types";
import { StyledTextField } from "theme/styledComponents";
import { pages } from "pages";
import { 
	initializeResetPasswordFlow,
	selectInitializeResetPasswordFlowError,
	selectInitializeResetPasswordFlowStatus
} from "store/reducers/auth/authReducer";
import { StoreStatus } from "store/common";
import { useAppDispatch, useAppSelector } from "hooks";
import { getErrorMessage } from "helpers/api";
import ContentError from "components/common/ContentPage/ContentError";


const PasswordResetStep1: FunctionComponent<WrappedComponentProps> = ({
	intl
}) => {
	
	const {
		register,
		formState: { errors },
		handleSubmit
	} = useForm();

	const initializeResetPasswordFlowStatus = useAppSelector(selectInitializeResetPasswordFlowStatus);
	const initializeResetPasswordFlowError = useAppSelector(selectInitializeResetPasswordFlowError);
	
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const submitForm = async (payload: FieldValues) => {
		dispatch(initializeResetPasswordFlow(payload as ResetPasswordRequest));
	};

	useEffect(() => {
		if (initializeResetPasswordFlowStatus === StoreStatus.Succeeded) {
			toast.success(
				`🙌 ${intl.formatMessage({
					id: "app.successResetPasswordRequest"
				})}`
			);
			navigate(pages.login.url());
		}
	}, [initializeResetPasswordFlowStatus, intl, navigate]);

	return (
		<div className="password-reset-wrapper">
			<div className="logo-wrapper">
				<Logo />
			</div>
			<form
				className="password-reset-form"
				onSubmit={handleSubmit(submitForm)}
			>
				<StyledTextField
					label={intl.formatMessage({ id: "app.email" })}
					{...register("email", {
						required: true
					})}
					helperText={
						!!errors.email &&
						intl.formatMessage({ id: "app.emptyField" })
					}
					error={!!errors.email}
				/>
				<ContentError error={getErrorMessage(intl, initializeResetPasswordFlowError)} />
				<LoadingButton
					isLoading={initializeResetPasswordFlowStatus === StoreStatus.InProgress}
					size={25}
					type="submit"
					label={intl.formatMessage({ id: "app.reset" })}
				/>
			</form>
			<TextWithLink
				text={intl.formatMessage({ id: "app.rememberPassword" })}
				linkHref={pages.login.url()}
				buttonText={intl.formatMessage({ id: "app.signIn" })}
			/>
		</div>
	);
};

export default injectIntl(PasswordResetStep1);
