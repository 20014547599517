import { FunctionComponent } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { useForm, Controller, FieldValues } from "react-hook-form";
import { shareAccessLevel } from "consts";
import moment from "moment";
import {
	TextField,
	FormControl,
	Select,
	MenuItem,
	IconButton
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useAppDispatch, useAppSelector } from "hooks";
import { AccessLevel } from "api/Types";
import { styled } from "@mui/material/styles";
import { createShareLink, selectShareLinkUpdateStatus } from "store/reducers/shareLinks/shareLinkReducer";
import { StoreStatus } from "store/common";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";

const StyledForm = styled("form")(({ theme }) => ({
	display: "flex",
	[theme.breakpoints.down("md")]: {
		flexDirection: "column"
	}
}));

const DueDateTextField = styled(TextField)(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		flexGrow: 1
	}
}));

const DueDateWrapper = styled("div")(({ theme }) => ({
	display: "flex",
	marginLeft: "1rem",
	[theme.breakpoints.down("md")]: {
		marginTop: "0.5rem",
		marginLeft: 0
	}
}));

const StyledFormControl = styled(FormControl)({
	flexGrow: 1
});

const ShareLinkForm: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({
	workspaceId, 
	intl,
}) => {
	const dispatch = useAppDispatch();

	const { handleSubmit, control, register } = useForm({
		shouldUnregister: true
	});

	const updateStatus = useAppSelector(selectShareLinkUpdateStatus);

	const submitShareLink = async ({ accessLevel, dueDate }: FieldValues) => {
		const data: {
			access_level: AccessLevel;
			due_date?: string;
		} = {
			access_level: accessLevel
		};

		if (dueDate) {
			data.due_date = new Date(dueDate).toISOString();
		}

		dispatch(createShareLink({
			workspaceId,
			data
		}))
	};

	return (
		<StyledForm onSubmit={handleSubmit(submitShareLink)}>
			<StyledFormControl>
				<Controller
					render={({ field }) => (
						<Select {...field}>
							{shareAccessLevel.map((level) => (
								<MenuItem key={level.value} value={level.value}>
									{intl.formatMessage({
										id: level.translationId
									})}
								</MenuItem>
							))}
						</Select>
					)}
					control={control}
					name="accessLevel"
					rules={{ required: true }}
					defaultValue={1}
				/>
			</StyledFormControl>
			<DueDateWrapper>
				<DueDateTextField
					type="date"
					{...register("dueDate")}
					InputLabelProps={{
						shrink: true
					}}
					InputProps={{
						inputProps: {
							min: moment(new Date()).format("YYYY-MM-DD")
						}
					}}
					label={intl.formatMessage({ id: "app.dueDate" })}
				/>
				<div style={{ marginTop: "0.3rem" }}>
					<IconButton
						disabled={updateStatus === StoreStatus.InProgress}
						type="submit"
						style={{
							marginLeft: "1rem"
						}}
					>
						<LinkIcon />
					</IconButton>
				</div>
			</DueDateWrapper>
		</StyledForm>
	);
};

export default injectIntl(ShareLinkForm);
