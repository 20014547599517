import { Link, To } from "react-router-dom";
import { Typography, Button, Divider } from "@mui/material";
import { FunctionComponent } from "react";

type ListHeaderSectionProps = {
	pageTitle: string;
	btnHref?: To;
	btnText?: string;
};

const ListHeaderSection: FunctionComponent<ListHeaderSectionProps> = ({
	pageTitle,
	btnHref = null,
	btnText = null
}) => {
	return (
		<>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginBottom: 10
				}}
			>
				<Typography variant="h5">{pageTitle}</Typography>
				{!!btnHref && !!btnText && (
					<Link to={btnHref}>
						<Button
							color="primary"
							variant="contained"
							// component={Link}
							// to={btnHref}
							style={{ marginLeft: 20 }}
						>
							{btnText}
						</Button>
					</Link>
				)}
			</div>
			<Divider />
		</>
	);
};

export default ListHeaderSection;
