import { FunctionComponent, ReactNode } from "react";
import { useAppSelector } from "hooks";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Navigation from "./Navigation/Navigation";
import ContentWrapper from "./ContentWrapper";

const sideNavigationWidth = 250;
const mobileNavigationHeight = 50;

type LayoutProps = {
	workspaceId?: number;
	children: ReactNode;
};

const WorkspaceLayout: FunctionComponent<LayoutProps> = ({
	workspaceId, children
}) => {
	const theme = useTheme();
	
	const isNavbarOpen = useAppSelector((state) => state.layout.isNavbarOpen);
	const isMobileNavDisplayed = useMediaQuery(theme.breakpoints.down("sm"));

	const marginLeft = isNavbarOpen && !isMobileNavDisplayed ? sideNavigationWidth : 0;
	const marginBottom = isMobileNavDisplayed ? mobileNavigationHeight : 0;

	return (
		<>
			{workspaceId && (
				<>
					<Navigation
						sideNavigationWidth={sideNavigationWidth}
						mobileNavigationHeight={mobileNavigationHeight}
						open={isNavbarOpen}
						workspaceId={workspaceId}
					/>
					<ContentWrapper
						marginLeft={marginLeft}
						marginBottom={marginBottom}
					>
						{children}
					</ContentWrapper>
				</>
			)}
		</>
	);
};

export default WorkspaceLayout;
