import { Dish, DishCreate, Dishes, DishUpdate, SetTags } from "./Types";
import { Tags } from "../Tags/Types";
import { AxiosInstance } from "axios";
import { handle, handleData } from "../handlers";
import { ApiDataResponse, ApiResponse } from "../Types";
import { User } from "../Users/Types";

export interface DishFilter {
  name?: string;
  ids?: number[];
}

export class DishClient {
  axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async getFavorites(
    workspaceId: number,
    filter?: DishFilter
  ): Promise<ApiDataResponse<Dishes>> {
    return await this._getDishes('favorites', workspaceId, filter);
  }

  async getDishes(
    workspaceId: number,
    filter?: DishFilter
  ): Promise<ApiDataResponse<Dishes>> {
    return await this._getDishes('dishes', workspaceId, filter);
  }

  private async _getDishes(
    endpoint: 'dishes' | 'favorites',
    workspaceId: number,
    filter?: DishFilter
  ): Promise<ApiDataResponse<Dishes>> {
    let url = `/api/v1/workspaces/${workspaceId}/` + endpoint;
    let query: string[] = [];

    if (filter) {
      if (filter.name) {
        query.push("filter{name}=" + encodeURIComponent(filter.name));
      }

      if (filter.ids) {
        filter.ids.forEach(id => {
          query.push("filter{id}[]=" + encodeURIComponent(id));
        });
      }
    }

    if (query.length) {
      url += "?" + query.join("&");
    }

    return await handleData<Dishes>(() => this.axios.get(url));
  }

  async getDish(
    workspaceId: number,
    dishId: number
  ): Promise<ApiDataResponse<Dish>> {
    return await handleData<Dish>(() =>
      this.axios.get(`/api/v1/workspaces/${workspaceId}/dishes/${dishId}`)
    );
  }

  async createDish(
    workspaceId: number,
    dish: DishCreate
  ): Promise<ApiDataResponse<Dish>> {
    return await handleData<Dish>(() =>
      this.axios.post(`/api/v1/workspaces/${workspaceId}/dishes`, dish)
    );
  }

  async updateDish(
    workspaceId: number,
    dishId: number,
    dish: DishUpdate
  ): Promise<ApiDataResponse<Dish>> {
    return await handleData<Dish>(() =>
      this.axios.patch(
        `/api/v1/workspaces/${workspaceId}/dishes/${dishId}`,
        dish
      )
    );
  }

  async deleteDish(workspaceId: number, dishId: number): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.delete(`/api/v1/workspaces/${workspaceId}/dishes/${dishId}`)
    );
  }

  async setDropDish(workspaceId: number, dishId: number): Promise<ApiResponse> {
    return await handleData<User>(() =>
      this.axios.post(
        `/api/v1/workspaces/${workspaceId}/dishes/${dishId}/drops`
      )
    );
  }

  async setUseDish(workspaceId: number, dishId: number): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.post(`/api/v1/workspaces/${workspaceId}/dishes/${dishId}/uses`)
    );
  }

  async setDishTags(
    workspaceId: number,
    dishId: number,
    tags: SetTags
  ): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.put(
        `/api/v1/workspaces/${workspaceId}/dishes/${dishId}/tags`,
        tags
      )
    );
  }

  async getDishTags(
    workspaceId: number,
    dishId: number
  ): Promise<ApiDataResponse<Tags>> {
    return await handleData<Tags>(() =>
      this.axios.get(`/api/v1/workspaces/${workspaceId}/dishes/${dishId}/tags`)
    );
  }

  async addDishTag(
    workspaceId: number,
    dishId: number,
    tagsId: number
  ): Promise<ApiDataResponse<Tags>> {
    return await handleData<Tags>(() =>
      this.axios.post(
        `/api/v1/workspaces/${workspaceId}/dishes/${dishId}/tags/${tagsId}`
      )
    );
  }

  async deleteDishTag(
    workspaceId: number,
    dishId: number,
    tagsId: number
  ): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.delete(
        `/api/v1/workspaces/${workspaceId}/dishes/${dishId}/tags/${tagsId}`
      )
    );
  }

  async setFavoriteDish(
    workspaceId: number,
    dishId: number
  ): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.put(`/api/v1/workspaces/${workspaceId}/favorites/${dishId}`)
    );
  }

  async unsetFavoriteDish(
    workspaceId: number,
    dishId: number
  ): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.delete(`/api/v1/workspaces/${workspaceId}/favorites/${dishId}`)
    );
  }
}
