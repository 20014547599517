import { Tag, TagCreate, Tags, TagUpdate } from "./Types";
import { AxiosInstance } from "axios";
import { ApiDataResponse, ApiResponse } from "../Types";
import { handle, handleData } from "../handlers";

export class TagsClient {
  axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async getTags(workspaceId: number): Promise<ApiDataResponse<Tags>> {
    return await handleData<Tags>(() =>
      this.axios.get(`/api/v1/workspaces/${workspaceId}/tags`)
    );
  }

  async getTag(
    workspaceId: number,
    tagId: number
  ): Promise<ApiDataResponse<Tag>> {
    return await handleData<Tag>(() =>
      this.axios.get(`/api/v1/workspaces/${workspaceId}/tags/${tagId}`)
    );
  }

  async createTag(
    workspaceId: number,
    tag: TagCreate
  ): Promise<ApiDataResponse<Tag>> {
    return await handleData<Tag>(() =>
      this.axios.post(`/api/v1/workspaces/${workspaceId}/tags`, tag)
    );
  }

  async updateTag(
    workspaceId: number,
    tagId: number,
    tag: TagUpdate
  ): Promise<ApiDataResponse<Tag>> {
    return await handleData<Tag>(() =>
      this.axios.patch(`/api/v1/workspaces/${workspaceId}/tags/${tagId}`, tag)
    );
  }

  async deleteTag(workspaceId: number, tagId: number): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.delete(`/api/v1/workspaces/${workspaceId}/tags/${tagId}`)
    );
  }
}
