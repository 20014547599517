import { languageStorageKey } from "consts";
import English from "lang/en.json";
import Polish from "lang/pl.json";

export interface Language {
	locale: string;
	language: any;
	intlLocale: string;
}

const languages: Language[] = [
	{ locale: "en_GB", language: English, intlLocale: "en" },
	{ locale: "en_US", language: English, intlLocale: "en" },
	{ locale: "pl", language: Polish, intlLocale: "pl" }
];

export const defineLanguage = (): Language => {
	const locale =
		localStorage.getItem(languageStorageKey) || navigator.language;
	return languages.find((item) => item.locale === locale) || languages[0];
};
