import { FunctionComponent } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dishes from "./Dishes/Dishes";
import { pages } from "pages";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { Mode } from "store/reducers/dishes/dishesReducer";

const WorkspacePage: FunctionComponent<WorkspaceContextComponentProps> = ({ workspaceId }) => {
	return (
		<Routes>
			<Route path={pages.workspace.suggested.pattern} element={
				<Dishes workspaceId={workspaceId} mode={Mode.Suggested} />
			} />
			<Route path={pages.workspace.dishes.pattern} element={
				<Dishes workspaceId={workspaceId} mode={Mode.Dishes} />
			} />
			<Route path={pages.workspace.favorites.pattern} element={
				<Dishes workspaceId={workspaceId} mode={Mode.Favorites} />
			} />
			<Route path="*" element={
				<Navigate to={pages.workspace.suggested.url(workspaceId)} />
			} />
		</Routes>
	)
};

export default WorkspacePage;
