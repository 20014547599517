import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "components/composed/Layout/Layout";
import Login from "components/composed/Login/Login";
import Register from "components/composed/Register/Register";
import ProfilePage from "components/composed/ProfilePage/ProfilePage";
import PasswordResetStep1 from "components/composed/Login/PasswordResetStep1";
import PasswordResetStep2 from "components/composed/Login/PasswordResetStep2";
import Confirm from "components/composed/Register/Confirm";
import ProtectedRoute from "components/common/ProtectedRoute/ProtectedRoute";
import MainPage from "components/composed/MainPage/MainPage";
import RouteNotFound from "components/composed/RouteNotFound/RouteNotFound";
import WorkspacesPage from "components/composed/WorkspacesPage/WorkspacesPage";
import WorkspaceForm from "components/composed/WorkspacesPage/WorkspaceForm/WorkspaceForm";
import Workspace from "components/composed/WorkspacesPage/Workspace";
import { pages } from "pages";

const App = () => {
	return (
		<Router>
			<Layout>
				<Routes>
					<Route path={pages.main.pattern} element={
						<ProtectedRoute>
							<MainPage />
						</ProtectedRoute>
					} />
					<Route path={pages.profile.pattern} element={
						<ProtectedRoute>
							<ProfilePage />
						</ProtectedRoute>
					} />
					<Route path={pages.workspaces.pattern} element={
						<ProtectedRoute>
							<WorkspacesPage />
						</ProtectedRoute>
					} />
					<Route path={pages.workspaceCreate.pattern} element={
						<ProtectedRoute>
							<WorkspaceForm />
						</ProtectedRoute>
					} />
					<Route path={pages.login.pattern} element={
						<ProtectedRoute reverse>
							<Login />
						</ProtectedRoute>
					} />
					<Route path={pages.register.pattern} element={
						<ProtectedRoute reverse>
							<Register />
						</ProtectedRoute>
					} />
					<Route path={pages.confirmCode.pattern} element={
						<ProtectedRoute reverse>
							<Confirm />
						</ProtectedRoute>
					} />
					<Route path={pages.resetPassword.pattern} element={
						<ProtectedRoute reverse>
							<PasswordResetStep1 />
						</ProtectedRoute>
					} />
					<Route path={pages.resetPasswordCode.pattern} element={
						<ProtectedRoute reverse>
							<PasswordResetStep2 />
						</ProtectedRoute>
					} />
					<Route path={pages.workspace.pattern} element={
						<ProtectedRoute>
							<Workspace />
						</ProtectedRoute>
					} />
					<Route element={
						<ProtectedRoute>
							<RouteNotFound />
						</ProtectedRoute>
					} />
				</Routes>
			</Layout>
		</Router>
	);
};

export default App;
