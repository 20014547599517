import { FunctionComponent } from "react";
import { Dish } from "api/Dishes/Types";
import {
	Chip,
	IconButton,
	styled,
	Typography
} from "@mui/material";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tag } from "api/Tags/Types";

type DishCardProps = {
	dish: Dish;
	onNameClick?: () => void;
	onFavoriteClick?: () => void;
	onUseClick?: () => void;
	onDropClick?: () => void;
	onEditClick?: () => void;
	onDeleteClick?: () => void;
};

const Card = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	padding: '0.5em',
	margin: '0.5em',
})

const CardIcon = styled('div')({
	padding: '1em'
})

const CardArea = styled('div')({
	flexGrow: '1',
})

const CardActions = styled('div')({
})

const DishCard: FunctionComponent<DishCardProps> = ({
	dish,
	onNameClick = undefined,
	onFavoriteClick = undefined,
	onUseClick = undefined,
	onDropClick = undefined,
	onEditClick = undefined,
	onDeleteClick = undefined
}) => {

	
	return (
		<Card>
			<CardIcon>
				<AccessAlarmIcon />
			</CardIcon>
			<CardArea>
				<Typography variant="h5">
					<span onClick={onNameClick}>{dish.name}</span>
				</Typography>
				<div>
					{dish.tags?.map((tag: Tag) => (
						<Chip
							key={tag.name}
							label={
								<Typography
									variant="caption"
									style={{
										whiteSpace:
											"normal"
									}}
								>
									{tag.name}
								</Typography>
							}
							size="small"
							style={{
								color: tag.font_color,
								backgroundColor: tag.background_color,
								margin: "0.5rem"
							}}
						/>
					))}
				</div>
			</CardArea>
			<CardActions>
				{onFavoriteClick && 
					<IconButton onClick={onFavoriteClick}>
						{dish.favorite 
							? (<FavoriteIcon />)
							: (<FavoriteBorderIcon />)}
					</IconButton>
				}
				{onUseClick && 
					<IconButton onClick={onUseClick}>
						<ThumbUpIcon />
					</IconButton>
				}
				{onDropClick &&
					<IconButton onClick={onDropClick}>
						<ThumbDownIcon />
					</IconButton>
				}
				{onEditClick &&
					<IconButton onClick={onEditClick}>
						<EditIcon />
					</IconButton>
				}
				{onDeleteClick &&
					<IconButton onClick={onDeleteClick}>
						<DeleteIcon />
					</IconButton>
				}
			</CardActions>
		</Card>
	);
};

export default DishCard;
