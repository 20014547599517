import { FunctionComponent, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { toast } from "react-toastify";
import Logo from "components/common/Logo/Logo";
import LoadingButton from "components/common/LoadingButton/LoadingButton";

import "./login.css";
import { ResetPassword } from "api/Users/Types";
import { StyledTextField } from "theme/styledComponents";
import { pages } from "pages";
import { useAppDispatch, useAppSelector } from "hooks";
import { resetPassword, selecteResetPasswordError, selectResetPasswordStatus } from "store/reducers/auth/authReducer";
import { StoreStatus } from "store/common";
import { getErrorMessage } from "helpers/api";
import ContentError from "components/common/ContentPage/ContentError";


type PasswordResetStep2Params = {
	code?: string;
};

type PasswordResetStep2FormSubmit = (payload: FieldValues) => Promise<void>;

const PasswordResetStep2: FunctionComponent<WrappedComponentProps> = ({
	intl
}) => {
	
	const { code } = useParams<PasswordResetStep2Params>();
	const {
		register,
		formState: { errors },
		handleSubmit,
		watch
	} = useForm();

	const resetPasswordStatus = useAppSelector(selectResetPasswordStatus);
	const resetPasswordError = useAppSelector(selecteResetPasswordError);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const submitForm: PasswordResetStep2FormSubmit = async ({ password }) => {
		if (code) {
			const payload: ResetPassword = {
				code,
				plain_password: password
			};
			dispatch(resetPassword(payload));
		}
	};

	useEffect(() => {
		if (resetPasswordStatus === StoreStatus.Succeeded) {
			toast.success(
				`🙌 ${intl.formatMessage({
					id: "app.successResetPassword"
				})}`
			);
			navigate(pages.login.url());
		}
	}, [resetPasswordStatus, intl, navigate]);

	return (
		<div className="password-reset-wrapper">
			<div className="logo-wrapper">
				<Logo />
			</div>
			<form
				className="password-reset-form"
				onSubmit={handleSubmit(submitForm)}
			>
				<StyledTextField
					label={intl.formatMessage({ id: "app.password" })}
					type="password"
					{...register("password", { required: true, minLength: 3 })}
					// TODO: requirements for password (min,max)
					helperText={
						(errors.password?.type === "required" &&
							intl.formatMessage({ id: "app.emptyField" })) ||
						(errors.password?.type === "minLength" &&
							intl.formatMessage({ id: "app.passwordLength" }))
					}
					error={!!errors.password}
				/>
				<StyledTextField
					label={intl.formatMessage({ id: "app.confirmPassword" })}
					type="password"
					{...register("confirmPassword", {
						required: true,
						minLength: 3,
						validate: (value) => value === watch("password")
					})}
					// TODO: requirements for password (min,max)
					helperText={
						(errors.confirmPassword?.type === "required" &&
							intl.formatMessage({ id: "app.emptyField" })) ||
						(errors.confirmPassword?.type === "minLength" &&
							intl.formatMessage({ id: "app.passwordLength" })) ||
						(errors.confirmPassword?.type === "validate" &&
							intl.formatMessage({ id: "app.samePassword" }))
					}
					error={!!errors.confirmPassword}
				/>
				<ContentError error={getErrorMessage(intl, resetPasswordError)} />
				<LoadingButton
					isLoading={resetPasswordStatus === StoreStatus.InProgress}
					size={25}
					type="submit"
					label={intl.formatMessage({ id: "app.reset" })}
				/>
			</form>
		</div>
	);
};

export default injectIntl(PasswordResetStep2);
