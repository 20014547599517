import { FunctionComponent, ReactNode } from "react";
import { CircularProgress } from "@mui/material";
import ContentError from "./ContentError";

type ContentLoaderProps = {
	loading?: boolean;
	error?: string;
	children?: ReactNode;
};

const ContentLoader: FunctionComponent<ContentLoaderProps> = ({
	loading,
	error = undefined,
	children
}) => {

	if (error) {
		return (<ContentError error={error} />);
	}
	
	return (
		<>
			{!loading ? (
				children
			) : (
				<CircularProgress style={{ marginTop: 10 }} />
			)}
		</>
	);
};

export default ContentLoader;
