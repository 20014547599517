import {
	createAsyncThunk,
	createSlice,
	PayloadAction
} from "@reduxjs/toolkit";
import api from "api";
import { DefaultGenerateQuery, GenerateQuery, Ranking } from "api/Generator/Types";
import { extractErrors, StoreError, StoreStatus } from "store/common";
import { RootState } from "store/store";

interface GeneratorState {
	query: GenerateQuery;
	ranking?: Ranking;
	fetchStatus: StoreStatus;
  	fetchError?: StoreError;
}

const initialState: GeneratorState = {
	query: DefaultGenerateQuery,
	ranking: undefined,
	fetchStatus: StoreStatus.Idle,
  	fetchError: undefined,
};

export const selectQuery = (state: RootState): GenerateQuery => state.generator.query;
export const selectRanking = (state: RootState): Ranking => state.generator.ranking;
export const selectRankingFetchStatus = (state: RootState): StoreStatus => state.generator.fetchStatus;
export const selectRankingFetchError = (state: RootState): StoreError => state.generator.fetchError;

export const generatorSlice = createSlice({
	name: "generator",
	initialState,
	reducers: {
		setQuery: (state, action: PayloadAction<GenerateQuery>) => {
			state.query = action.payload;
		},
		resetFetchStatus: (state) => {
			state.fetchStatus = StoreStatus.Idle;
			state.fetchError = undefined;
		}
	},
	extraReducers(builder) {
		builder
		  .addCase(fetchRanking.pending, (state) => {
			state.fetchStatus = StoreStatus.InProgress;
		  })
		  .addCase(fetchRanking.fulfilled, (state, action) => {
			state.fetchStatus = StoreStatus.Succeeded;
			if (action.payload) {
			  state.ranking = action.payload;
			}
		  })
		  .addCase(fetchRanking.rejected, (state, action) => {
			state.fetchStatus = StoreStatus.Failed
			state.fetchError = extractErrors(action);
		  })
	}
});

type FetchRankingParams = {
	workspaceId: number,
	query: GenerateQuery
}

export const fetchRanking = createAsyncThunk('generator/fetchRanking', async (params: FetchRankingParams, { rejectWithValue }) => {
	try {
		const { workspaceId, query } = params;
		const resp = await api.generator.getRanking(workspaceId, query);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const { resetFetchStatus, setQuery } = generatorSlice.actions;

const generatorReducer = generatorSlice.reducer;

export default generatorReducer;
