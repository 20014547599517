import { Invitation, Invitations, InvitationUpdate } from "./Types";
import { AxiosInstance } from "axios";
import { ApiDataResponse, ApiResponse } from "../Types";
import { handle, handleData } from "../handlers";

export class InvitationClient {
  axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async getInvitations(
    workspaceId: number
  ): Promise<ApiDataResponse<Invitations>> {
    return await handleData<Invitations>(() =>
      this.axios.get(`/api/v1/workspaces/${workspaceId}/invitations`)
    );
  }

  async updateInvitation(
    workspaceId: number,
    invitationId: number,
    invitation: InvitationUpdate
  ): Promise<ApiDataResponse<Invitation>> {
    return await handleData<Invitation>(() =>
      this.axios.patch(
        `/api/v1/workspaces/${workspaceId}/invitations/${invitationId}`,
        invitation
      )
    );
  }

  async deleteInvitation(
    workspaceId: number,
    invitationId: number
  ): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.delete(
        `/api/v1/workspaces/${workspaceId}/invitations/${invitationId}`
      )
    );
  }
}
