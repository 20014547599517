import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { toast } from "react-toastify";
import {
	FormControlLabel,
	Checkbox,
	FormControl,
	FormHelperText
} from "@mui/material";
import Logo from "components/common/Logo/Logo";
import TextWithLink from "components/common/TextWithLink/TextWithLink";
import LoadingButton from "components/common/LoadingButton/LoadingButton";
import { emailPattern } from "consts";
import { StyledTextField } from "theme/styledComponents";
import { pages } from "pages";
import { useAppDispatch, useAppSelector } from "hooks";

import { registerUser, selectRegisterUserError, selectRegisterUserStatus } from "store/reducers/auth/authReducer";
import { StoreStatus } from "store/common";
import ContentError from "components/common/ContentPage/ContentError";
import { getErrorMessage } from "helpers/api";

import "./register.css";
import { useErrors } from "hooks/useErrors";
import { getFieldErrorMessage } from "helpers/errors";

type RegisterFormSubmit = (payload: FieldValues) => Promise<void>;

const Register: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const {
		register,
		setError,
		formState: { errors },
		handleSubmit,
		watch
	} = useForm();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const registerUserStatus = useAppSelector(selectRegisterUserStatus);
	const registerUserError = useAppSelector(selectRegisterUserError);

	const submitForm: RegisterFormSubmit = async ({
		username,
		email,
		password,
		terms
	}) => {
		const newUser = {
			username,
			email,
			plain_password: password,
			agree_terms: terms
		};
		dispatch(registerUser(newUser));
	};

	useEffect(() => {
		if (registerUserStatus === StoreStatus.Succeeded) {
			toast.success(
				`🙌 ${intl.formatMessage({ id: "app.successRegister" })}`
			);
			navigate(pages.login.url());
		}
	}, []);

	useErrors(registerUserError, setError, intl, {
		'plain_password': 'password'
	});

	return (
		<div className="registration-wrapper">
			<div className="logo-wrapper">
				<Logo />
			</div>
			<form
				className="registration-form"
				onSubmit={handleSubmit(submitForm)}
			>
				<StyledTextField
					label={intl.formatMessage({ id: "app.username" })}
					{...register("username", { required: true })}
					helperText={getFieldErrorMessage(errors.username, intl)}
					error={!!errors.username}
				/>
				<StyledTextField
					label={intl.formatMessage({ id: "app.email" })}
					{...register("email", {
						required: true,
						pattern: emailPattern
					})}
					helperText={getFieldErrorMessage(errors.email, intl, {
						"minLength": "app.passwordLength",
						"validate": "app.samePassword"
					})}					
					// helperText={
					// 	!!errors.email &&
					// 	intl.formatMessage({ id: "app.wrongEmail" })
					// }
					error={!!errors.email}
				/>
				<StyledTextField
					label={intl.formatMessage({ id: "app.password" })}
					type="password"
					{...register("password", { required: true, minLength: 3 })}
					helperText={getFieldErrorMessage(errors.password, intl, {
						"minLength": "app.passwordLength"
					})}
					error={!!errors.password}
				/>
				<StyledTextField
					label={intl.formatMessage({ id: "app.confirmPassword" })}
					type="password"
					{...register("confirmPassword", {
						required: true,
						minLength: 3,
						validate: (value) => value === watch("password")
					})}
					helperText={getFieldErrorMessage(errors.confirmPassword, intl, {
						"minLength": "app.passwordLength",
						"validate": "app.samePassword"
					})}
					error={!!errors.confirmPassword}
				/>
				<FormControl error={!!errors.terms}>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								{...register("terms", { required: true })}
							/>
						}
						label={
							<>
								<span>Accept</span>
								<a
									rel="noopener noreferrer"
									target="_blank"
									href="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies"
									style={{ marginLeft: 5 }}
								>
									Terms of use
								</a>
							</>
						}
						sx={{
							fontsize: '0.875rem'
						}}
					/>
					{errors.terms && (
						<FormHelperText>
							{getFieldErrorMessage(errors.confirmPassword, intl, {
								"required": "app.termsRequired"
							})}
						</FormHelperText>
					)}
				</FormControl>

				<ContentError error={getErrorMessage(intl, registerUserError)} />
				<LoadingButton
					isLoading={registerUserStatus === StoreStatus.InProgress}
					size={25}
					type="submit"
					label={intl.formatMessage({ id: "app.signUp" })}
				/>
			</form>

			<TextWithLink
				text={intl.formatMessage({ id: "app.alreadyHaveAccount" })}
				linkHref={pages.login.url()}
				buttonText={intl.formatMessage({ id: "app.signIn" })}
			/>
		</div>
	);
};

export default injectIntl(Register);
