import {
  CredentialsUpdate,
  EmailConfirm,
  ResetPassword,
  ResetPasswordRequest,
  User,
  UserRegisterData,
  UserUpdateData,
} from "./Types";
import { AxiosInstance } from "axios";
import { ApiDataResponse, ApiResponse } from "../Types";
import { handle, handleData } from "../handlers";

export class UserClient {
  axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async register(user: UserRegisterData): Promise<ApiDataResponse<User>> {
    return await handleData<User>(() => this.axios.post(`/api/v1/users`, user));
  }

  async update(
    userId: number,
    user: UserUpdateData
  ): Promise<ApiDataResponse<User>> {
    return await handleData<User>(() =>
      this.axios.post(`/api/v1/users/${userId}`, user)
    );
  }

  async getProfile(): Promise<ApiDataResponse<User>> {
    return await handleData<User>(() => this.axios.get(`/api/v1/profile`));
  }

  async changePassword(
    userId: number,
    credentials: CredentialsUpdate
  ): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.post(`/api/v1/users/${userId}/credentials`, credentials)
    );
  }

  async requestResetPassword(data: ResetPasswordRequest): Promise<ApiResponse> {
    return await handle(() => this.axios.post(`/api/v1/reset-password`, data));
  }

  async resetPassword(data: ResetPassword): Promise<ApiResponse> {
    return await handleData<User>(() => this.axios.post(`/api/v1/reset`, data));
  }

  async confirmEmail(data: EmailConfirm): Promise<ApiResponse> {
    return await handleData<User>(() =>
      this.axios.post(`/api/v1/email-confirm`, data)
    );
  }
}
