import { FunctionComponent, useEffect, useState } from "react";
import ShareEmailForm from "./ShareEmailForm/ShareEmailForm";
import {
	Typography,
	Divider,
	CircularProgress,
	FormControl,
	Select,
	MenuItem
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ShareEmailList from "./ShareEmailList/ShareEmailList";
import InvitationList from "./InvitationList/InvitationList";
import { toast } from "react-toastify";
import SimpleDialog from "components/common/SimpleDialog/SimpleDialog";
import { shareAccessLevel } from "consts";
import { styled } from "@mui/material/styles";

import { 
	deleteShare, 
	selectShareDeleteError, 
	selectShareDeleteStatus, 
	selectShares, 
	selectShareUpdateError, 
	selectShareUpdateStatus, 
	updateShare, 
	deleteInvitation,
	selectInvitationDeleteError,
	selectInvitationDeleteStatus,
	selectInvitations,
	selectInvitationUpdateError,
	selectInvitationUpdateStatus,
	updateInvitation
} from "store/reducers/shares/shareReducer";
import { AccessLevel } from "api/Types";
import { StoreStatus } from "store/common";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";

const ContentWrapper = styled("div")(({ theme }) => ({
	flexBasis: "50%",
	[theme.breakpoints.down("md")]: {
		flexBasis: "100%"
	}
}));

const ShareEmail: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const [isDeleteShareDialogOpened, setDeleteShareDialogOpened] =
		useState(false);
	const [isEditShareDialogOpened, setEditShareDialogOpened] = useState(false);
	const [choosenShareId, setChoosenShareId] = useState<number | null>(null);
	const [isDeleteInvitationDialogOpened, setDeleteInvitationDialogOpened] =
		useState(false);
	const [isEditInvitationDialogOpened, setEditInvitationDialogOpened] =
		useState(false);
	const [choosenInvitationId, setChoosenInvitationId] = useState<
		number | null
	>(null);
	const [newAccessLevel, setNewAccessLevel] = useState<AccessLevel | null>(
		null
	);
	const dispatch = useAppDispatch();
	
	const shares = useAppSelector(selectShares);
	const updateShareError = useAppSelector(selectShareUpdateError);
	const updateShareStatus = useAppSelector(selectShareUpdateStatus);
	const deleteShareError = useAppSelector(selectShareDeleteError);
	const deleteShareStatus = useAppSelector(selectShareDeleteStatus);

	const invitations = useAppSelector(selectInvitations);
	const updateInvitationError = useAppSelector(selectInvitationUpdateError);
	const updateInvitationStatus = useAppSelector(selectInvitationUpdateStatus);
	const deleteInvitationError = useAppSelector(selectInvitationDeleteError);
	const deleteInvitationStatus = useAppSelector(selectInvitationDeleteStatus);

	const onDeleteShareClick = async () => {
		if (choosenShareId) {
			dispatch(deleteShare({
				workspaceId,
				shareId: choosenShareId,
			}));
		}
	};

	useEffect(() => {
		if (deleteShareStatus === StoreStatus.Succeeded || StoreStatus.Failed) {
			closeDeleteShareDialog();
		}
	}, [ deleteShareStatus ]);

	useEffect(() => {
		if (deleteShareError) {
			toast.error(
				`☠️ ${intl.formatMessage({ id: "app.somethingWentWrong" })}`
			);
		}
	}, [ deleteShareError ]);

	const onEditShareSubmit = async () => {
		if (choosenShareId && newAccessLevel) {
			dispatch(updateShare({
				workspaceId,
				shareId: choosenShareId,
				data: {
					access_level: newAccessLevel,
				}
			}))
		}
	};

	useEffect(() => {
		if (updateShareStatus === StoreStatus.Succeeded || StoreStatus.Failed) {
			closeEditShareDialog();
		}
	}, [ updateShareStatus ]);

	useEffect(() => {
		if (updateShareError) {
			toast.error(
				`☠️ ${intl.formatMessage({ id: "app.somethingWentWrong" })}`
			);
		}
	}, [ updateShareError ]);

	const onDeleteInvitation = async () => {
		if (choosenInvitationId && newAccessLevel) {
			dispatch(deleteInvitation({
				workspaceId,
				invitationId: choosenInvitationId
			}));
		}
	};

	useEffect(() => {
		if (deleteInvitationStatus === StoreStatus.Succeeded || StoreStatus.Failed) {
			closeDeleteInvitationDialog();
		}
	}, [ deleteInvitationStatus ]);

	useEffect(() => {
		if (deleteInvitationError) {
			toast.error(
				`☠️ ${intl.formatMessage({ id: "app.somethingWentWrong" })}`
			);
		}
	}, [ deleteInvitationError ]);

	const editInvitation = async () => {
		if (choosenInvitationId && newAccessLevel) {
			dispatch(updateInvitation({
				workspaceId,
				invitationId: choosenInvitationId,
				data: {
					access_level: newAccessLevel,
				}
			}));
		}
	};

	useEffect(() => {
		if (updateInvitationStatus === StoreStatus.Succeeded || StoreStatus.Failed) {
			closeEditInvitationDialog();
		}
	}, [ updateInvitationStatus ]);

	useEffect(() => {
		if (updateInvitationError) {
			toast.error(
				`☠️ ${intl.formatMessage({ id: "app.somethingWentWrong" })}`
			);
		}
	}, [ updateInvitationError ]);

	const closeDeleteShareDialog = () => {
		setDeleteShareDialogOpened(false);
	};

	const closeEditShareDialog = () => {
		setEditShareDialogOpened(false);
	};

	const closeDeleteInvitationDialog = () => {
		setDeleteInvitationDialogOpened(false);
	};

	const closeEditInvitationDialog = () => {
		setEditInvitationDialogOpened(false);
	};

	return (
		<ContentWrapper>
			<ShareEmailForm workspaceId={workspaceId} />
			<div style={{ marginTop: "1rem" }}>
				<Typography variant="subtitle1">
					{intl.formatMessage({ id: "app.sharedWith" })}:
				</Typography>
			</div>
			<Divider />
			{shares === null ? (
				<div style={{ marginTop: 20, textAlign: "center" }}>
					<CircularProgress />
				</div>
			) : shares?.length ? (
				<>
					<ShareEmailList
						shares={shares}
						setChoosenShareId={setChoosenShareId}
						setEditShareDialogOpened={setEditShareDialogOpened}
						setDeleteShareDialogOpened={setDeleteShareDialogOpened}
					/>
					<SimpleDialog
						open={isDeleteShareDialogOpened}
						onClose={closeDeleteShareDialog}
						dialogContent={intl.formatMessage({
							id: "app.removeShareConfirmation"
						})}
						cancelButtonText={intl.formatMessage({
							id: "app.cancel"
						})}
						onConfirmButton={onDeleteShareClick}
						confirmButtonText={intl.formatMessage({
							id: "app.delete"
						})}
						isConfirmInProgress={deleteShareStatus === StoreStatus.InProgress}
					/>
					<SimpleDialog
						open={isEditShareDialogOpened}
						onClose={closeEditShareDialog}
						dialogContent={
							<FormControl
								style={{ width: 250 }}
							>
								<Select
									value={newAccessLevel || AccessLevel.View}
									onChange={(e) =>
										setNewAccessLevel(
											e.target.value as AccessLevel
										)
									}
								>
									{shareAccessLevel.map((level) => (
										<MenuItem
											key={level.value}
											value={level.value}
										>
											{intl.formatMessage({
												id: level.translationId
											})}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						}
						cancelButtonText={intl.formatMessage({
							id: "app.cancel"
						})}
						onConfirmButton={onEditShareSubmit}
						confirmButtonText={intl.formatMessage({
							id: "app.save"
						})}
						isConfirmInProgress={updateShareStatus === StoreStatus.InProgress}
					/>
				</>
			) : (
				<Typography variant="subtitle1">
					{intl.formatMessage({ id: "app.emptyShares" })}
				</Typography>
			)}
			<div style={{ marginTop: "1rem" }}>
				<Typography variant="subtitle1">
					{intl.formatMessage({ id: "app.awaitingInvitations" })}:
				</Typography>
			</div>
			<Divider />
			{invitations === null ? (
				<div style={{ marginTop: 20, textAlign: "center" }}>
					<CircularProgress />
				</div>
			) : invitations?.length ? (
				<>
					<InvitationList
						invitations={invitations}
						setChoosenInvitationId={setChoosenInvitationId}
						setEditInvitationDialogOpened={
							setEditInvitationDialogOpened
						}
						setDeleteInvitationDialogOpened={
							setDeleteInvitationDialogOpened
						}
					/>
					<SimpleDialog
						open={isDeleteInvitationDialogOpened}
						onClose={closeDeleteInvitationDialog}
						dialogContent={intl.formatMessage({
							id: "app.removeInvitationConfirmation"
						})}
						cancelButtonText={intl.formatMessage({
							id: "app.cancel"
						})}
						onConfirmButton={onDeleteInvitation}
						confirmButtonText={intl.formatMessage({
							id: "app.delete"
						})}
						isConfirmInProgress={deleteShareStatus === StoreStatus.InProgress}
					/>
					<SimpleDialog
						open={isEditInvitationDialogOpened}
						onClose={closeEditInvitationDialog}
						dialogContent={
							<FormControl
								style={{ width: 250 }}
							>
								<Select
									value={newAccessLevel || 1}
									onChange={(e) =>
										setNewAccessLevel(
											e.target.value as AccessLevel
										)
									}
								>
									{shareAccessLevel.map((level) => (
										<MenuItem
											key={level.value}
											value={level.value}
										>
											{intl.formatMessage({
												id: level.translationId
											})}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						}
						cancelButtonText={intl.formatMessage({
							id: "app.cancel"
						})}
						onConfirmButton={editInvitation}
						confirmButtonText={intl.formatMessage({
							id: "app.save"
						})}
						isConfirmInProgress={updateInvitationStatus === StoreStatus.InProgress}
					/>
				</>
			) : (
				<Typography variant="subtitle1">
					{intl.formatMessage({ id: "app.emptyInvitations" })}
				</Typography>
			)}
		</ContentWrapper>
	);
};

export default injectIntl(ShareEmail);
