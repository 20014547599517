import { ApiError } from "./handlers";
import { SpicifyApi, SpicifyApiStatic } from "./Spicify";
import { Credentials, TokenStorage } from "./Types";

export { SpicifyApi } from "./Spicify";

let _apiClient: SpicifyApi;

export function configure(
  baseUrl: string,
  storage: TokenStorage,
  onSessionCreated: () => void,
  onSessionDestroyed: () => void,
  onMissingSession: () => void
) {
  _apiClient = new SpicifyApi(
    baseUrl,
    storage,
    onSessionCreated,
    onSessionDestroyed,
    onMissingSession
  );
}

const api: SpicifyApiStatic = {
  isApiError(payload: any): payload is ApiError {
    return payload && 'isApiError' in payload && payload.isApiError;
  },
  
  get dishes() {
    return _apiClient.dishes;
  },
  get generator() {
    return _apiClient.generator;
  },
  get invitations() {
    return _apiClient.invitations;
  },
  get shares() {
    return _apiClient.shares;
  },
  get shareLinks() {
    return _apiClient.shareLinks;
  },
  get tags() {
    return _apiClient.tags;
  },
  get users() {
    return _apiClient.users;
  },
  get workspaces() {
    return _apiClient.workspaces;
  },

  async login(credentials: Credentials) {
    return _apiClient.login(credentials);
  },

  rebuild_session(): void {
    _apiClient.rebuild_session();
  },

  logout() {
    return _apiClient.logout();
  },

  session() {
    return _apiClient.session();
  },

  async refresh() {
    return _apiClient.refresh();
  },

  async healthcheck() {
    return _apiClient.healthcheck();
  },
};

export default api;
