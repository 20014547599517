import { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import api from "api";
import { PrimitiveType } from "react-intl";

export enum StoreStatus {
    Idle = 'idle',
    InProgress = 'loading',
    Succeeded = 'succeeded',
    Failed = 'failed',
}

type FieldError = {
    error: string,
    params: Record<string, PrimitiveType>
}

export type FieldErrors = Record<string, FieldError[]>;

export type StoreError = {
    message: string | undefined,
    params?: Record<string, PrimitiveType>,
    fieldErrors?: FieldErrors
};

export function extractErrors<T, V>(action: PayloadAction<T, string, V, SerializedError>): StoreError {
    if (api.isApiError(action.payload)) {
        if (action.payload.status === 406) {
            return {
                message: action.payload.message,
                params: action.payload.data
            }
        }

        if (action.payload.status === 400) {
            return {
                message: action.payload.message,
                fieldErrors: action.payload.data
            }
        }
    }
        
    return {
        message: action.error.message,
    }
}