import { FunctionComponent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { Link, useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { ButtonsWrapper } from "theme/styledComponents";
import { TextField, Button } from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ContentPage from "components/common/ContentPage/ContentPage";
import { 
	createWorkspace, 
	fetchWorkspace, 
	resetUpdateWorkspace, 
	selectWorkspace, 
	selectWorkspacesFetchError, 
	selectWorkspacesFetchStatus,
	selectWorkspaceUpdateError,
	selectWorkspaceUpdateStatus,
	updateWorkspace
} from "store/reducers/workspaces/workspacesReducer";
import { pages } from "pages";
import ContentError from "components/common/ContentPage/ContentError";
import { StoreStatus } from "store/common";
import SubmitButton from "components/common/SubmitButton";
import { toast } from "react-toastify";
import { getFieldErrorMessage } from "helpers/errors";
import { getErrorMessage } from "helpers/api";

interface WorkspaceFormProps {
	workspaceId?: number;
}

const WorkspaceForm: FunctionComponent<WorkspaceFormProps & WrappedComponentProps> = (
	{ workspaceId, intl }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const workspace = useAppSelector((state) => selectWorkspace(state, workspaceId));
	const fetchStatus = useAppSelector(selectWorkspacesFetchStatus);
	const fetchError = useAppSelector(selectWorkspacesFetchError);
	const updateStatus = useAppSelector(selectWorkspaceUpdateStatus);
	const updateError = useAppSelector(selectWorkspaceUpdateError);

	useEffect(() => {
		if (workspaceId) {
			if (fetchStatus === StoreStatus.Idle) {
				dispatch(fetchWorkspace(workspaceId))
			}
		}
	}, [ workspace, fetchStatus, dispatch, workspaceId ])

	useEffect(() => {
		if (updateStatus === StoreStatus.Succeeded) {
			dispatch(resetUpdateWorkspace());
			navigate(pages.workspaces.url());
		}
	}, [ updateStatus, navigate, dispatch ])


	const onSubmit = async ({ workspaceName }: FieldValues) => {
		const data = {
			name: workspaceName
		};

		if (workspaceId)
			dispatch(updateWorkspace({
				workspaceId,
				data
			}));
		else {
			dispatch(createWorkspace(data));
		}
	};

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors }
	} = useForm({
		defaultValues: {
			workspaceName: workspace?.name || ""
		}
	});

	useEffect(() => {
		if (updateStatus === StoreStatus.Failed) {
			if (!updateError) {
				toast.error(
					`☠️ ${intl.formatMessage({ id: "app.somethingWentWrong" })}`
				);
			}
		}
	}, [ updateError, updateStatus, setError, intl ])

	if (fetchError) {
		return <ContentError error={fetchError?.message} />
	}

	return (
		<ContentPage
			title={
				workspaceId
					? intl.formatMessage({ id: "app.editWorkspace" })
					: intl.formatMessage({ id: "app.addWorkspace" })
			}
			closeLink={pages.workspaces.url()}
			loading={fetchStatus === StoreStatus.InProgress}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.name" })}
					{...register("workspaceName", { required: true })}
					name="workspaceName"
					helperText={getFieldErrorMessage(errors.workspaceName, intl, {
						"duplicated": "app.workspaceAlreadyExist"
					})}
					error={!!errors.workspaceName}
					style={{ marginTop: 10 }}
				/>
				<ContentError error={getErrorMessage(intl, updateError)} />
				<ButtonsWrapper>
					<Button
						color="secondary"
						variant="contained"
						component={Link}
						disabled={updateStatus === StoreStatus.InProgress}
						to={pages.workspaces.url()}
						style={{ marginRight: 10 }}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<SubmitButton
						caption={intl.formatMessage({ id: "app.save" })}
						inProgress={updateStatus === StoreStatus.InProgress}
						/>
				</ButtonsWrapper>
			</form>
		</ContentPage>
	);
};

export default injectIntl(WorkspaceForm);
