import { FunctionComponent, useEffect, useState } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { useParams } from "react-router-dom";
import {
	Typography,
	FormControl,
	FormLabel,
	FormGroup,
	Chip
} from "@mui/material";
import { TagGroups, tagsToFormControls } from "helpers/tags";
import ContentPage from "components/common/ContentPage/ContentPage";
import { pages } from "pages";
import { useAppDispatch, useAppSelector } from "hooks";
import {
	fetchDish,
	selectDish,
	selectDishesFetchStatus
} from "store/reducers/dishes/dishesReducer";
import Recipe from "./Recipe";
import { StoreStatus } from "store/common";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";
import { getNumberParam } from "helpers/views";


type DishViewParams = {
	dishId?: string;
};

const DishView: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const dispatch = useAppDispatch();
	const params = useParams<DishViewParams>();
	const [ dishId, paramError ] = getNumberParam(params.dishId, "dishId", intl);

	const dish = useAppSelector(state => selectDish(state, dishId))
	const fetchStatus = useAppSelector(selectDishesFetchStatus);

	const [formattedTags, setFormattedTags] = useState<TagGroups | null>(null);

	useEffect(() => {
		if (dishId) {
			dispatch(fetchDish({
				workspaceId,
				dishId
			}));
		}
	}, [dishId, workspaceId]);

	useEffect(() => {
		if (dish) {
			setFormattedTags(tagsToFormControls(dish.tags || []));
		}
	}, [ dish ]);

	return (
		<ContentPage
			title={dish?.name ||  ""}
			editLink={pages.workspace.dishEdit.url(workspaceId, dish?.id || 0)}
			closeLink={pages.workspace.dishes.url(workspaceId)}
			loading={fetchStatus === StoreStatus.InProgress}
			error={paramError}
		>
			{dish?.recipe && (<Recipe recipe={dish?.recipe} />)}
			{dish?.kilocalories ? (
				<Typography
					variant="body1"
					style={{ marginTop: "0.5rem" }}
				>
					{intl.formatMessage({ id: "app.kilocalories" }) +
						":" +
						` ${dish.kilocalories}`}
				</Typography>
			) : null}
			{formattedTags?.length ? (
				<FormControl component="fieldset" fullWidth>
					<FormLabel
						style={{
							textAlign: "center",
							marginTop: "0.8rem"
						}}
					>
						{intl.formatMessage({ id: "app.tags" })}
					</FormLabel>
					{formattedTags.map((tag) => (
						<FormControl
							aria-label="position"
							key={tag.groupName}
							style={{ margin: "0 auto" }}
						>
							<FormLabel
								style={{
									textAlign: "center",
									marginTop: "0.8rem"
								}}
							>
								{tag.groupName}
							</FormLabel>
							<FormGroup row>
								{tag.values.map((tagValue) => {
									return (
										<Chip
											key={tagValue.name}
											label={
												<Typography
													variant="caption"
													style={{
														whiteSpace:
															"normal"
													}}
												>
													{tagValue.name}
												</Typography>
											}
											size="small"
											style={{
												color: tagValue.font_color,
												backgroundColor:
													tagValue.background_color,
												margin: "0.5rem"
											}}
										/>
									);
								})}
							</FormGroup>
						</FormControl>
					))}
				</FormControl>
			) : null}
		</ContentPage>
	);
};

export default injectIntl(DishView);
