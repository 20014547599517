import {
	createAsyncThunk,
	createSlice
} from "@reduxjs/toolkit";
import { Tag, TagCreate, Tags, TagUpdate } from "api/Tags/Types";
import api from "api";
import { extractErrors, StoreError, StoreStatus } from "store/common";
import { RootState } from "store/store";

interface TagsState {
	tags: Tags;
	fetchStatus: StoreStatus;
	fetchError?: StoreError;
	updateStatus: StoreStatus;
	updateError?: StoreError;
	deleteStatus: StoreStatus;
	deleteError?: StoreError;
}

const initialState: TagsState = {
	tags: [],
	fetchStatus: StoreStatus.Idle,
  	fetchError: undefined,
	updateStatus: StoreStatus.Idle,
	updateError: undefined,
	deleteStatus: StoreStatus.Idle,
	deleteError: undefined
};

export const selectTags = (state: RootState): Tags => state.tags.tags;
export const selectTag = (state: RootState, tagId?: number): Tag | null => {
	return state.tags.tags.find((tag: Tag) => tag.id === tagId);
}
export const selectTagsFetchError = (state: RootState): StoreError | undefined => state.tags.fetchError;
export const selectTagsFetchStatus = (state: RootState): StoreStatus => state.tags.fetchStatus;
export const selectTagUpdateError = (state: RootState): StoreError | undefined => state.tags.updateError;
export const selectTagUpdateStatus = (state: RootState): StoreStatus => state.tags.updateStatus;
export const selectTagDeleteError = (state: RootState): StoreError | undefined => state.tags.deleteError;
export const selectTagDeleteStatus = (state: RootState): StoreStatus => state.tags.deleteStatus;

function updateTags(current: Tags, update: Tags): Tags {
	update.forEach(tag => {
		const tagIndex = current.findIndex(t => t.id === tag.id);
		if (tagIndex >= 0) {
			current[tagIndex] = tag;
		} else {
			current.push(tag);
		}
	});

	return current;
}

export const tagsSlice = createSlice({
	name: "tags",
	initialState,
	reducers: {
		resetUpdateTag: (state) => {
			state.updateStatus = StoreStatus.Idle;
			state.updateError = undefined;
		}
	},
	extraReducers(builder) {
	  builder
		.addCase(fetchTags.pending, (state) => {
		  state.fetchStatus = StoreStatus.InProgress;
		})
		.addCase(fetchTags.fulfilled, (state, action) => {
		  state.fetchStatus = StoreStatus.Succeeded;
		  if (action.payload) {
			state.tags = updateTags(state.tags, action.payload);
		  }
		})
		.addCase(fetchTags.rejected, (state, action) => {
		  state.fetchStatus = StoreStatus.Failed
		  state.fetchError = extractErrors(action)
		})
		.addCase(fetchTag.pending, (state) => {
		  state.fetchStatus = StoreStatus.InProgress;
		})
		.addCase(fetchTag.fulfilled, (state, action) => {
		  state.fetchStatus = StoreStatus.Succeeded;
		  if (action.payload) {
			if (action.payload) {
				state.tags = updateTags(state.tags, [ action.payload ]);
			}
		  }
		})
		.addCase(fetchTag.rejected, (state, action) => {
		  state.fetchStatus = StoreStatus.Failed
		  state.fetchError = extractErrors(action)
		})
		.addCase(createTag.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(createTag.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			if (action.payload) {
				state.tags = updateTags(state.tags, [ action.payload ]);
			}
		  }
		})
		.addCase(createTag.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action)
		})
		.addCase(updateTag.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(updateTag.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			if (action.payload) {
				state.tags = updateTags(state.tags, [ action.payload ]);
			}
		  }
		})
		.addCase(updateTag.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action)
		})
		.addCase(deleteTag.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(deleteTag.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
		  	state.tags = state.tags.filter((tag) => tag.id !== action.payload);
		  }
		})
		.addCase(deleteTag.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action)
		})
	}
});

export const fetchTags = createAsyncThunk('tags/fetchTags', async (workspaceId: number, { rejectWithValue }) => {
	try {
		const resp = await api.tags.getTags(workspaceId);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface TagParams {
	workspaceId: number;
	tagId: number;
}

export const fetchTag = createAsyncThunk('tags/fetchTag', async (params: TagParams, { rejectWithValue }) => {
	try {
		const { workspaceId, tagId } = params;
		const resp = await api.tags.getTag(workspaceId, tagId);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface CreateTagParams {
	workspaceId: number,
	data: TagCreate
}

export const createTag = createAsyncThunk('tags/createTag', async (params: CreateTagParams, { rejectWithValue }) => {
	try {
		const { workspaceId, data } = params;
		const resp = await api.tags.createTag(workspaceId, data);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface UpdateTagParams {
	workspaceId: number,
	tagId: number,
	data: TagUpdate
}

export const updateTag = createAsyncThunk('tags/updateTag', async (params: UpdateTagParams, { rejectWithValue }) => {
	try {
		const { workspaceId, tagId, data } = params;
		const resp = await api.tags.updateTag(workspaceId, tagId, data);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const deleteTag = createAsyncThunk('tags/deleteTag', async (params: TagParams, { rejectWithValue }) => {
	try {
		const { workspaceId, tagId } = params;
		await api.tags.deleteTag(workspaceId, tagId);
		return tagId;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const { resetUpdateTag } = tagsSlice.actions;

const tagsReducer = tagsSlice.reducer;

export default tagsReducer;
