import { FunctionComponent } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent
} from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import DialogButton from "./DialogButton";

interface DeleteDialogProps {
	message: React.ReactNode;
	isOpen: boolean;
	deleteInProgress: boolean;
	onClose: () => void;
	onDelete: () => void;
}

const DeleteDialog: FunctionComponent<DeleteDialogProps & WrappedComponentProps> = ({
	message,
	isOpen,
	deleteInProgress,
	onClose,
	onDelete,
	intl
}) => {
	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogContent>
				{message}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<DialogButton 
					caption={intl.formatMessage({ id: "app.Delete" })}
					inProgress={deleteInProgress}
					onClick={onDelete}
				/>
			</DialogActions>
		</Dialog>
	);
};

export default injectIntl(DeleteDialog);
