import { FunctionComponent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { injectIntl, WrappedComponentProps } from "react-intl";
import Logo from "components/common/Logo/Logo";
import TextWithLink from "components/common/TextWithLink/TextWithLink";
import LoadingButton from "components/common/LoadingButton/LoadingButton";
import "./login.css";
import { selectLoginError, selectLoginStatus, login } from "store/reducers/auth/authReducer";
import { Credentials } from "api/Types";
import { StyledTextField } from "theme/styledComponents";
import { pages } from "pages";
import { StoreStatus } from "store/common";
import ContentError from "components/common/ContentPage/ContentError";
import { getFieldErrorMessage } from "helpers/errors";
import { getErrorMessage } from "helpers/api";


const Login: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const status = useAppSelector(selectLoginStatus);
	const error = useAppSelector(selectLoginError);

	const {
		register,
		formState: { errors },
		handleSubmit
	} = useForm();

	const submitForm = async (payload: FieldValues) => {
		await dispatch(login(payload as Credentials));
	};

	useEffect(() => {
		if (status === StoreStatus.Succeeded) {
			navigate(pages.main.url());
		}
	}, [ status, navigate ])

	return (
		<div className="login-wrapper">
			<div className="logo-wrapper">
				<Logo />
			</div>
			<form className="login-form" onSubmit={handleSubmit(submitForm)}>
				<StyledTextField
					{...register("username", {
						required: true
					})}
					label={intl.formatMessage({ id: "app.username" })}
					helperText={getFieldErrorMessage(errors.username, intl)}
					error={!!errors.username}
				/>
				<StyledTextField
					{...register("password", { required: true })}
					label={intl.formatMessage({ id: "app.password" })}
					type="password"
					helperText={getFieldErrorMessage(errors.password, intl)}
					error={!!errors.password}
				/>
				<ContentError error={getErrorMessage(intl, error)} />
				<LoadingButton
					isLoading={status === StoreStatus.InProgress}
					size={25}
					type="submit"
					label={intl.formatMessage({ id: "app.signIn" })}
				/>
			</form>
			<TextWithLink
				text={intl.formatMessage({ id: "app.dontHaveAccount" })}
				linkHref={pages.register.url()}
				buttonText={intl.formatMessage({ id: "app.signUp" })}
			/>
			<TextWithLink
				text={intl.formatMessage({ id: "app.forgotPassword" })}
				linkHref={pages.resetPassword.url()}
				buttonText={intl.formatMessage({ id: "app.reset" })}
			/>
		</div>
	);
};

export default injectIntl(Login);
