import axios, { AxiosResponse } from "axios";
import { ApiDataResponse, ApiResponse } from "./Types";

type ApiCallback<T> = () => Promise<AxiosResponse<T>>;

export class ApiError extends Error {
  readonly isApiError: boolean = true;
  readonly status: number;
  readonly data?: any;

  constructor(status: number, message: string, data?: any) {
    super(message);
    this.status = status;
    this.data = data;
  }
}

export async function handle(c: ApiCallback<ApiResponse>): Promise<ApiResponse> {
  try {
    let r = await c();
    return new ApiResponse(r.status);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        throw new ApiError(
          err.response.data.code,
          err.response.data.message,
          err.response.data.context,
        );
      }
    }

    throw err;
  }
}

export async function handleData<T>(c: ApiCallback<ApiDataResponse<T>>): Promise<ApiDataResponse<T>> {
  try {
    let r = await c();
    return new ApiDataResponse<T>(r.status, r.data as T);
  } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          throw new ApiError(
            err.response.data.code,
            err.response.data.message,
            err.response.data.context,
          );
        }
      }
  
      throw err;
  }
}
