import { TextField } from "@mui/material";
import { styled } from "@mui/system";

export const ButtonsWrapper = styled("div")({
	display: "flex",
	justifyContent: "flex-end",
	marginTop: 10
});

export const TitleWrapper = styled("div")({
	display: "flex",
	alignItems: "right",
	justifyContent: "space-between"
});

export const StyledTextField = styled(TextField)({
	margin: "0.8rem 0"
});

export const StyledListItem = styled("li")({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	textDecoration: "none"
});