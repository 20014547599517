import { FunctionComponent, useEffect, useState } from "react";
import {
	Divider,
	FormControl,
	MenuItem,
	Select,
	Typography,
	CircularProgress
} from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { shareAccessLevel } from "consts";
import { toast } from "react-toastify";
import ShareLinkForm from "./ShareLinkForm/ShareLinkForm";
import ShareLinkList from "./ShareLinkList/ShareLinkList";
import SimpleDialog from "components/common/SimpleDialog/SimpleDialog";
import { AccessLevel } from "api/Types";
import { useAppDispatch, useAppSelector } from "hooks";
import { styled } from "@mui/material/styles";
import { deleteShareLink, fetchShareLinks, selectShareLinkDeleteError, selectShareLinkDeleteStatus, selectShareLinksFetchStatus, selectShareLinks, selectShareLinkUpdateError, selectShareLinkUpdateStatus, updateShareLink } from "store/reducers/shareLinks/shareLinkReducer";
import { StoreStatus } from "store/common";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";

const ContentWrapper = styled("div")(({ theme }) => ({
	marginLeft: "1rem",
	[theme.breakpoints.down("md")]: {
		marginLeft: 0
	}
}));

const ShareLink: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({
	workspaceId,
	intl,
}) => {
	const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false);
	const [isEditDialogOpened, setEditDialogOpened] = useState(false);
	const [choosenLinkId, setChoosenLinkId] = useState<number | null>(null);
	const [newAccessLevel, setNewAccessLevel] = useState<AccessLevel | null>(
		null
	);
	const dispatch = useAppDispatch();
	const shareLinks = useAppSelector(selectShareLinks);
	const fetchStatus = useAppSelector(selectShareLinksFetchStatus);
	const deleteStatus = useAppSelector(selectShareLinkDeleteStatus);
	const deleteError = useAppSelector(selectShareLinkDeleteError);
	const updateStatus = useAppSelector(selectShareLinkUpdateStatus);
	const updateError = useAppSelector(selectShareLinkUpdateError);


	useEffect(() => {
		if (fetchStatus === StoreStatus.Idle) {
			dispatch(fetchShareLinks(workspaceId));
		}
	}, [ fetchStatus ])

	const onShareLinkDelete = async () => {
		if (choosenLinkId) {
			dispatch(deleteShareLink({
				workspaceId,
				shareLinkId: choosenLinkId,
			}))
		}
	};

	useEffect(() => {
		if (deleteStatus === StoreStatus.Succeeded || deleteStatus === StoreStatus.Failed) {
			closeDeleteDialog();
		}
	}, [ deleteStatus ] );

	useEffect(() => {
		if (deleteError) {
			toast.error(
				`☠️ ${intl.formatMessage({ id: "app.somethingWentWrong" })}`
			);
		}
	}, [ deleteError ]);

	const editLink = async () => {
		if (choosenLinkId && newAccessLevel) {
			dispatch(updateShareLink({
				workspaceId,
				shareLinkId: choosenLinkId,
				data: {
					access_level: newAccessLevel,
				},
			}));
		}
	};



	useEffect(() => {
		if (deleteStatus === StoreStatus.Succeeded || deleteStatus === StoreStatus.Failed) {
			closeEditDialog();
		}
	}, [ updateStatus ] );

	useEffect(() => {
		if (updateError) {
			toast.error(
				`☠️ ${intl.formatMessage({ id: "app.somethingWentWrong" })}`
			);
		}
	}, [ updateError ]);

	const closeDeleteDialog = () => {
		setDeleteDialogOpened(false);
	};

	const closeEditDialog = () => {
		setEditDialogOpened(false);
	};

	return (
		<ContentWrapper>
			<ShareLinkForm workspaceId={workspaceId} />
			<div style={{ marginTop: "1rem" }}>
				<Typography variant="subtitle1">
					{intl.formatMessage({ id: "app.sharedLinks" })}:
				</Typography>
			</div>
			<Divider />
			{shareLinks === null ? (
				<div style={{ marginTop: 20, textAlign: "center" }}>
					<CircularProgress />
				</div>
			) : shareLinks?.length ? (
				<>
					<ShareLinkList
						shareLinks={shareLinks}
						setChoosenLinkId={setChoosenLinkId}
						setEditDialogOpened={setEditDialogOpened}
						setDeleteDialogOpened={setDeleteDialogOpened}
					/>
					<SimpleDialog
						open={isDeleteDialogOpened}
						onClose={closeDeleteDialog}
						dialogContent={intl.formatMessage({
							id: "app.removeLinkConfirmation"
						})}
						cancelButtonText={intl.formatMessage({
							id: "app.cancel"
						})}
						onConfirmButton={onShareLinkDelete}
						confirmButtonText={intl.formatMessage({
							id: "app.delete"
						})}
						isConfirmInProgress={deleteStatus === StoreStatus.InProgress}
					/>
					<SimpleDialog
						open={isEditDialogOpened}
						onClose={closeEditDialog}
						dialogContent={
							<FormControl
								style={{ width: 250 }}
							>
								<Select
									value={newAccessLevel || 1}
									onChange={(e) =>
										setNewAccessLevel(
											e.target.value as AccessLevel
										)
									}
								>
									{shareAccessLevel.map((level) => (
										<MenuItem
											key={level.value}
											value={level.value}
										>
											{intl.formatMessage({
												id: level.translationId
											})}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						}
						cancelButtonText={intl.formatMessage({
							id: "app.cancel"
						})}
						onConfirmButton={editLink}
						confirmButtonText={intl.formatMessage({
							id: "app.save"
						})}
						isConfirmInProgress={updateStatus === StoreStatus.InProgress}
					/>
				</>
			) : (
				<Typography variant="subtitle1">
					{intl.formatMessage({ id: "app.emptySharedLinks" })}
				</Typography>
			)}
		</ContentWrapper>
	);
};

export default injectIntl(ShareLink);
