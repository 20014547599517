import { Share, ShareCreate, Shares, ShareUpdate } from "./Types";
import { AxiosInstance } from "axios";
import { ApiDataResponse, ApiResponse } from "../Types";
import { handle, handleData } from "../handlers";

export class ShareClient {
  axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async getShares(workspaceId: number): Promise<ApiDataResponse<Shares>> {
    return await handleData<Shares>(() =>
      this.axios.get(`/api/v1/workspaces/${workspaceId}/shares`)
    );
  }

  async getShare(
    workspaceId: number,
    shareId: number
  ): Promise<ApiDataResponse<Share>> {
    return await handleData<Share>(() =>
      this.axios.get(`/api/v1/workspaces/${workspaceId}/shares/${shareId}`)
    );
  }

  async createShare(
    workspaceId: number,
    share: ShareCreate
  ): Promise<ApiDataResponse<Share>> {
    return await handleData<Share>(() =>
      this.axios.post(`/api/v1/workspaces/${workspaceId}/shares`, share)
    );
  }

  async updateShare(
    workspaceId: number,
    shareId: number,
    share: ShareUpdate
  ): Promise<ApiDataResponse<Share>> {
    return await handleData<Share>(() =>
      this.axios.patch(
        `/api/v1/workspaces/${workspaceId}/shares/${shareId}`,
        share
      )
    );
  }

  async deleteShare(
    workspaceId: number,
    shareId: number
  ): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.delete(`/api/v1/workspaces/${workspaceId}/shares/${shareId}`)
    );
  }
}
