import { Tags } from "api/Tags/Types";

export type TagGroup = {
	groupName: string;
	values: Tags;
};
export type TagGroups = TagGroup[];

export const tagsToFormControls = (tags: Tags): TagGroups => {
	const groups: TagGroups = [];
	tags.forEach((tag) => {
		const group = groups.find(
			(group) => tag.group === group.groupName
		);

		if (group) {
			const groupIndex = groups.indexOf(group);

			const updatedGroup = {
				groupName: group.groupName,
				values: [
					...group.values,
					tag
				]
			};
			groups[groupIndex] = updatedGroup;
		} else {
			groups.push({
				groupName: tag.group,
				values: [ tag ]
			});
		}
	});
	return groups;
};

interface TagItem {
	id: number;
	value: number;
}
export const prepareTagsArrForGenerator = (formData: any) => {
	const arr: TagItem[] = [];
	Object.keys(formData).forEach((key) => {
		if (!isNaN(Number(key))) {
			arr.push({
				id: Number(key),
				value: Number(formData[key])
			});
		}
	});
	return arr;
};
