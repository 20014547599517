import { signIn, signOut } from "store/reducers/auth/authReducer";
import { configure } from "api";
import { Store } from "@reduxjs/toolkit";
import { pages } from "pages";

export default function configureApi(store: Store)
{
	configure(
	
		window._env_.REACT_APP_API_DOMAIN,
		{
			getToken: (type) => localStorage.getItem(type),
			setToken: (type, token) => localStorage.setItem(type, token),
			removeToken: (type) => localStorage.removeItem(type),
		},
		() => store.dispatch(signIn()),
		() => window.location.href = '/#' + pages.login.url(),
		() => store.dispatch(signOut())
	);
};