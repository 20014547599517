import { FunctionComponent } from "react";
import {
	Button,
	CircularProgress,
} from "@mui/material";

interface SubmitButtonProps {
	caption: string;
	inProgress: boolean;
}

const SubmitButton: FunctionComponent<SubmitButtonProps> = ({
	caption,
	inProgress
}) => {
	
	return <Button color="primary" variant="contained" type="submit">
		{inProgress ? (
			<CircularProgress size={23} color="inherit" />
		) : caption}
	</Button>
};

export default SubmitButton;
