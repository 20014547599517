import { GenerateQuery, Ranking } from "./Types";
import { ApiDataResponse } from "../Types";
import { handleData } from "../handlers";
import { AxiosInstance } from "axios";

export class GeneratorClient {
  axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async getRanking(
    workspaceId: number,
    query: GenerateQuery
  ): Promise<ApiDataResponse<Ranking>> {
    return await handleData<Ranking>(() =>
      this.axios.post(`/api/v1/workspaces/${workspaceId}/generate`, query)
    );
  }
}
