import { FunctionComponent, ReactNode } from "react";
import { Typography, Button } from "@mui/material";
import { Link, To } from "react-router-dom";

type TextWithButtonProps = {
	text: ReactNode;
	buttonText: ReactNode;
	linkHref: To;
};

const TextWithButton: FunctionComponent<TextWithButtonProps> = ({
	text,
	buttonText,
	linkHref
}) => {
	return (
		<Typography style={{ textAlign: "center" }}>
			{text}
			<Link to={linkHref} style={{ textDecoration: "none" }}>
				<Button>{buttonText}</Button>
			</Link>
		</Typography>
	);
};

export default TextWithButton;
