import { FunctionComponent } from "react";
import { useAppSelector } from "hooks";
import { useTheme, styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
	IconButton,
	Toolbar,
	AppBar,
	Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import UserMenu from "./UserMenu/UserMenu";
import HideOnScroll from "components/common/HideOnScroll/HideOnScroll";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	zIndex: theme.zIndex.drawer + 1
}));

const ToolbarMiddle = styled("div")(({ theme }) => ({
	margin: "0 auto",
	display: "flex",
	alignItems: "center",
	maxWidth: 600,
	[theme.breakpoints.down("sm")]: {
		maxWidth: 340
	}
}));

type AppHeaderProps = {
	onToggleNavigation?: () => void;
}

const AppHeader: FunctionComponent<AppHeaderProps> = ({ onToggleNavigation }) => {
	const theme = useTheme();
	const isMobileNavDisplayed = useMediaQuery(theme.breakpoints.down("sm"));
	const isAuthenticated = useAppSelector(
		(state) => state.auth.isAuthenticated
	);

	const showMenuButton = onToggleNavigation !== undefined;

	return (
		<>
			<HideOnScroll direction="down">
				<StyledAppBar>
					<Toolbar>
						{showMenuButton && isAuthenticated && !isMobileNavDisplayed && (
							<div style={{ marginRight: "auto" }}>
								<IconButton
									edge="start"
									color="inherit"
									aria-label="menu"
									onClick={onToggleNavigation}
								>
									<MenuIcon />
								</IconButton>
							</div>
						)}
						<ToolbarMiddle>
							<Typography variant="h6">Spicify</Typography>
						</ToolbarMiddle>
						{isAuthenticated && <UserMenu />}
					</Toolbar>
				</StyledAppBar>
			</HideOnScroll>
			<Toolbar />
		</>
	);
};

export default AppHeader;
