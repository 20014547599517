import {
	createAsyncThunk,
	createSlice
} from "@reduxjs/toolkit";
import { ShareLink, ShareLinkCreate, ShareLinks, ShareLinkUpdate } from "api/ShareLinks/Types";
import api from "api";
import { extractErrors, StoreError, StoreStatus } from "store/common";
import { RootState } from "store/store";

interface ShareLinksState {
	shareLinks: ShareLinks;
	fetchStatus: StoreStatus;
	fetchError?: StoreError;
	updateStatus: StoreStatus;
	updateError?: StoreError;
	deleteStatus: StoreStatus;
	deleteError?: StoreError;
}

const initialState: ShareLinksState = {
	shareLinks: [],
	fetchStatus: StoreStatus.Idle,
  	fetchError: undefined,
	updateStatus: StoreStatus.Idle,
	updateError: undefined,
	deleteStatus: StoreStatus.Idle,
	deleteError: undefined
};

export const selectShareLinks = (state: RootState): ShareLinks => state.shareLinks.shareLinks;
export const selectShareLink = (state: RootState, workspaceId?: number): ShareLink | null =>
	state.shareLinks.shareLinks.find((workspace: ShareLink) => workspace.id === workspaceId);
export const selectShareLinksFetchError = (state: RootState): StoreError | undefined => state.shareLinks.fetchError;
export const selectShareLinksFetchStatus = (state: RootState): StoreStatus => state.shareLinks.fetchStatus;
export const selectShareLinkUpdateError = (state: RootState): StoreError | undefined => state.shareLinks.updateError;
export const selectShareLinkUpdateStatus = (state: RootState): StoreStatus => state.shareLinks.updateStatus;
export const selectShareLinkDeleteError = (state: RootState): StoreError | undefined => state.shareLinks.deleteError;
export const selectShareLinkDeleteStatus = (state: RootState): StoreStatus => state.shareLinks.deleteStatus;

function updateShareLinks(current: ShareLinks, update: ShareLinks): ShareLinks {
	update.forEach(shareLink => {
		const shareLinkIndex = current.findIndex(i => i.id === shareLink.id);
		if (shareLinkIndex >= 0) {
			current[shareLinkIndex] = shareLink;
		} else {
			current.push(shareLink);
		}
	});

	return current;
}

export const shareLinksSlice = createSlice({
	name: "shareLinks",
	initialState,
	reducers: {
		resetUpdateShareLink: (state) => {
			state.updateStatus = StoreStatus.Idle;
			state.updateError = undefined;
		}
	},
	extraReducers(builder) {
	  builder
		.addCase(fetchShareLinks.pending, (state) => {
		  state.fetchStatus = StoreStatus.InProgress;
		})
		.addCase(fetchShareLinks.fulfilled, (state, action) => {
		  state.fetchStatus = StoreStatus.Succeeded;
		  if (action.payload) {
			state.shareLinks = updateShareLinks(state.shareLinks, action.payload);
		  }
		})
		.addCase(fetchShareLinks.rejected, (state, action) => {
		  state.fetchStatus = StoreStatus.Failed
		  state.fetchError = extractErrors(action)
		})
		.addCase(fetchShareLink.pending, (state) => {
		  state.fetchStatus = StoreStatus.InProgress;
		})
		.addCase(fetchShareLink.fulfilled, (state, action) => {
		  state.fetchStatus = StoreStatus.Succeeded;
		  if (action.payload) {
			if (action.payload) {
				state.shareLinks = updateShareLinks(state.shareLinks, [ action.payload ]);
			}
		  }
		})
		.addCase(fetchShareLink.rejected, (state, action) => {
		  state.fetchStatus = StoreStatus.Failed
		  state.fetchError = extractErrors(action)
		})
		.addCase(createShareLink.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(createShareLink.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			if (action.payload) {
				state.shareLinks = updateShareLinks(state.shareLinks, [ action.payload ]);
			}
		  }
		})
		.addCase(createShareLink.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action)
		})
		.addCase(updateShareLink.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(updateShareLink.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			if (action.payload) {
				state.shareLinks = updateShareLinks(state.shareLinks, [ action.payload ]);
			}
		  }
		})
		.addCase(updateShareLink.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action)
		})
		.addCase(deleteShareLink.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(deleteShareLink.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
		  	state.shareLinks = state.shareLinks.filter((shareLink) => shareLink.id !== action.payload);
		  }
		})
		.addCase(deleteShareLink.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action)
		})
	}
});

export const fetchShareLinks = createAsyncThunk('shareLinks/fetchShareLinks', async (workspaceId: number, { rejectWithValue }) => {
	try {
		const resp = await api.shareLinks.getShareLinks(workspaceId);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface ShareLinkParams {
	workspaceId: number;
	shareLinkId: number;
}

export const fetchShareLink = createAsyncThunk('shareLinks/fetchShareLink', async (params: ShareLinkParams, { rejectWithValue }) => {
	try {
		const { workspaceId, shareLinkId } = params; 
		const resp = await api.shareLinks.getShareLink(workspaceId, shareLinkId);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface CreateShareLinkParams {
	workspaceId: number,
	data: ShareLinkCreate
}

export const createShareLink = createAsyncThunk('shareLinks/createShareLink', async (params: CreateShareLinkParams, { rejectWithValue }) => {
	try {
		const { workspaceId, data } = params;
		const resp = await api.shareLinks.createShareLink(workspaceId, data);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface UpdateShareLinkParams {
	workspaceId: number,
	shareLinkId: number,
	data: ShareLinkUpdate
}

export const updateShareLink = createAsyncThunk('shareLinks/updateShareLink', async (params: UpdateShareLinkParams, { rejectWithValue }) => {
	try {
		const { workspaceId, shareLinkId, data } = params;
		const resp = await api.shareLinks.updateShareLink(workspaceId, shareLinkId, data);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const deleteShareLink = createAsyncThunk('shareLinks/deleteShareLink', async (params: ShareLinkParams, { rejectWithValue }) => {
	try {
		const { workspaceId, shareLinkId } = params;
		await api.shareLinks.deleteShareLink(workspaceId, shareLinkId);
		return shareLinkId;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const { resetUpdateShareLink } = shareLinksSlice.actions;

const shareLinksReducer = shareLinksSlice.reducer;

export default shareLinksReducer;
