import { FunctionComponent, ReactNode } from "react";
import {
	List,
	ListItemText,
	ListItemIcon,
	ListItemButton,
	ListItem
} from "@mui/material";
import { NavLink } from "react-router-dom";

type SideNavigationItem = {
    icon: ReactNode;
    href: string;
    text: string;
};

type SideNavigationItems = SideNavigationItem[];

type SideNavigationProps = {
	listItems: SideNavigationItems;
};

const SideNavigation: FunctionComponent<SideNavigationProps> = ({
	listItems
}) => {
	return (
		<List
			sx={{
				paddingTop: 0,
				paddingBottom: 0
			}}
		>
			{listItems.map(({ text, icon, href }) => (
				<ListItem key={text} disablePadding>
					<ListItemButton		
						component={NavLink}
						to={href}
					>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={text} />
					</ListItemButton>
				</ListItem>
			))}
		</List>
	);
};

export default SideNavigation;
