import {
	createAsyncThunk,
	createSlice
} from "@reduxjs/toolkit";
import { Workspace, WorkspaceCreate, Workspaces, WorkspaceUpdate } from "api/Workspaces/Types";
import api from "api";
import { extractErrors, StoreError, StoreStatus } from "store/common";
import { RootState } from "store/store";
import { WorkspaceFilter } from "api/Workspaces/Workspace";

interface WorkspacesState {
	workspaces: Workspaces;
	currentWorkspaceId: number | undefined;
	fetchStatus: StoreStatus;
	fetchError?: StoreError;
	updateStatus: StoreStatus;
	updateError?: StoreError;
	deleteStatus: StoreStatus;
	deleteError?: StoreError;
}

const initialState: WorkspacesState = {
	workspaces: [],
	currentWorkspaceId: undefined,
	fetchStatus: StoreStatus.Idle,
  	fetchError: undefined,
	updateStatus: StoreStatus.Idle,
	updateError: undefined,
	deleteStatus: StoreStatus.Idle,
	deleteError: undefined
};

export const selectWorkspaces = (state: RootState): Workspaces => state.workspaces.workspaces;
export const selectWorkspace = (state: RootState, workspaceId?: number): Workspace | null =>
	state.workspaces.workspaces.find((workspace: Workspace) => workspace.id === workspaceId);
export const selectPersonalWorkspace = (state: RootState): Workspace | null =>
	state.workspaces.workspaces.find((workspace: Workspace) => workspace.personal);
export const selectCurrentWorkspace = (state: RootState): Workspace | null =>
	state.workspaces.workspaces.find((workspace: Workspace) => workspace.id === state.currentWorkspaceId);
export const selectWorkspacesFetchError = (state: RootState): StoreError | undefined => state.workspaces.fetchError;
export const selectWorkspacesFetchStatus = (state: RootState): StoreStatus => state.workspaces.fetchStatus;
export const selectWorkspaceUpdateError = (state: RootState): StoreError | undefined => state.workspaces.updateError;
export const selectWorkspaceUpdateStatus = (state: RootState): StoreStatus => state.workspaces.updateStatus;
export const selectWorkspaceDeleteError = (state: RootState): StoreError | undefined => state.workspaces.deleteError;
export const selectWorkspaceDeleteStatus = (state: RootState): StoreStatus => state.workspaces.deleteStatus;

function updateWorkspaces(current: Workspaces, update: Workspaces): Workspaces {
	update.forEach(workspace => {
		const workspaceIndex = current.findIndex(w => w.id === workspace.id);
		if (workspaceIndex >= 0) {
			current[workspaceIndex] = workspace;
		} else {
			current.push(workspace);
		}
	});

	return current;
}

export const workspacesSlice = createSlice({
	name: "workspaces",
	initialState,
	reducers: {
		resetUpdateWorkspace: (state) => {
			state.updateStatus = StoreStatus.Idle;
			state.updateError = undefined;
		}
	},
	extraReducers(builder) {
	  builder
		.addCase(fetchWorkspaces.pending, (state) => {
		  state.fetchStatus = StoreStatus.InProgress;
		})
		.addCase(fetchWorkspaces.fulfilled, (state, action) => {
		  state.fetchStatus = StoreStatus.Succeeded;
		  if (action.payload) {
			state.workspaces = updateWorkspaces(state.workspaces, action.payload);
		  }
		})
		.addCase(fetchWorkspaces.rejected, (state, action) => {
		  state.fetchStatus = StoreStatus.Failed
		  state.fetchError = extractErrors(action)
		})
		.addCase(fetchWorkspace.pending, (state) => {
		  state.fetchStatus = StoreStatus.InProgress;
		})
		.addCase(fetchWorkspace.fulfilled, (state, action) => {
		  state.fetchStatus = StoreStatus.Succeeded;
		  if (action.payload) {
			if (action.payload) {
				state.workspaces = updateWorkspaces(state.workspaces, [ action.payload ]);
			}
		  }
		})
		.addCase(fetchWorkspace.rejected, (state, action) => {
		  state.fetchStatus = StoreStatus.Failed
		  state.fetchError = extractErrors(action)
		})
		.addCase(createWorkspace.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(createWorkspace.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			if (action.payload) {
				state.workspaces = updateWorkspaces(state.workspaces, [ action.payload ]);
			}
		  }
		})
		.addCase(createWorkspace.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action)
		})
		.addCase(updateWorkspace.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(updateWorkspace.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			if (action.payload) {
				state.workspaces = updateWorkspaces(state.workspaces, [ action.payload ]);
			}
		  }
		})
		.addCase(updateWorkspace.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action)
		})
		.addCase(deleteWorkspace.pending, (state) => {
		  state.updateStatus = StoreStatus.InProgress;
		})
		.addCase(deleteWorkspace.fulfilled, (state, action) => {
		  state.updateStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
		  	state.workspaces = state.workspaces.filter((workspace) => workspace.id !== action.payload);
		  }
		})
		.addCase(deleteWorkspace.rejected, (state, action) => {
		  state.updateStatus = StoreStatus.Failed
		  state.updateError = extractErrors(action)
		})
	}
});

export const fetchWorkspaces = createAsyncThunk('workspaces/fetchWorkspaces', async (filter: WorkspaceFilter | undefined, { rejectWithValue }) => {
	try {
		const resp = await api.workspaces.getWorkspaces(filter);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const fetchWorkspace = createAsyncThunk('workspaces/fetchWorkspace', async (workspaceId: number, { rejectWithValue }) => {
	try {
		const resp = await api.workspaces.getWorkspace(workspaceId);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const createWorkspace = createAsyncThunk('workspaces/createWorkspace', async (data: WorkspaceCreate, { rejectWithValue }) => {
	try {
		const resp = await api.workspaces.createWorkspace(data);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface UpdateWorkspaceParams {
	workspaceId: number,
	data: WorkspaceUpdate
}

export const updateWorkspace = createAsyncThunk('workspaces/updateWorkspace', async (params: UpdateWorkspaceParams, { rejectWithValue }) => {
	try {
		const { workspaceId, data } = params;
		const resp = await api.workspaces.updateWorkspace(workspaceId, data);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const deleteWorkspace = createAsyncThunk('workspaces/deleteWorkspace', async (workspaceId: number, { rejectWithValue }) => {
	try {
		await api.workspaces.deleteWorkspace(workspaceId);
		return workspaceId;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const { resetUpdateWorkspace } = workspacesSlice.actions;

const workspacesReducer = workspacesSlice.reducer;

export default workspacesReducer;
