import { Alert } from "@mui/material";
import { FunctionComponent } from "react";

type ContentErrorProps = {
	error?: string;
};

const ContentError: FunctionComponent<ContentErrorProps> = ({
	error
}) => {
	if (!error) {
		return null;
	}

	console.log("error:");
	console.log(error);

	return <Alert style={{ marginTop: "10px" }} severity="error">{error}</Alert>;
};

export default ContentError;
