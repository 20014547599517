import { User } from "./Users/Types";
import { TokenType } from "./Spicify";

export class ApiResponse {
  status?: number;
  error?: Error;

  constructor(status?: number, err?: Error) {
    this.status = status;
    this.error = err;
  }
}

export class ApiDataResponse<T> extends ApiResponse {
  data?: T;

  constructor(status: number, data?: T, err?: Error) {
    super(status, err);
    this.data = data;
  }
}

export interface Healthcheck {
  status: string;
  version: string;
}

export interface TokenStorage {
  getToken(type: TokenType): string | null;
  setToken(type: TokenType, token: string): void;
  removeToken(type: TokenType): void;
}

export interface Credentials {
  username: string;
  password: string;
}

export interface RefreshToken {
  refresh_token: string;
}

export interface JwtToken {
  token: string;
  refresh_token: string;
}

export class Token {
  private readonly _issuedAt: Date;
  private readonly _expirationTime: Date;
  private readonly _roles: string[];
  private readonly _username: string;

  constructor(
    issuedAt: Date,
    expirationTime: Date,
    roles: string[],
    username: string
  ) {
    this._issuedAt = issuedAt;
    this._expirationTime = expirationTime;
    this._roles = roles;
    this._username = username;
  }

  get issuedAt(): Date {
    return this._issuedAt;
  }
  get expirationTime(): Date {
    return this._expirationTime;
  }
  get roles(): string[] {
    return this._roles;
  }
  get username(): string {
    return this._username;
  }

  get isExpired(): boolean {
    return this._expirationTime.getTime() < Date.now();
  }
}

export interface Error {
  code: number;
  message: string;
  context: any;
}

export enum TimeEffort {
  Short = 0,
  Medium = 1,
  Long = 2,
}

export enum AccessLevel {
  None = 0,
  View = 1,
  Edit = 2,
  Share = 3,
  Admin = 4,
  Owner = 5,
}

export enum ImpactValue {
  Exclude = -2,
  PreferNot = -1,
  Ignore = 0,
  Prefer = 1,
  Only = 2,
}

export enum Order {
  Descending = -1,
  Ascending = 1,
}

export interface Trackable {
  created_by: User;
  updated_by: User;
}

export interface Timestampable {
  created_at: DateTime;
  updated_at: DateTime;
}

export interface Ownable {
  owner: User;
}

export type DateTime = string;
export type Color = string;
