import { FunctionComponent, ReactNode } from "react";
import ContentPageHeader, { Breadcrumb } from "./ContentPageHeader";
import ContentLoader from "./ContentLoader";

type ContentPageProps = {
	breadcrumbs?: Breadcrumb[];
	title?: string;
	titleComponent?: ReactNode;
	backLink?: string;
	editLink?: string;
	closeLink?: string;
	settingsLink?: string;
	filterLink?: string;
	loading: boolean;
	error?: string;
	children?: ReactNode;
};

const ContentPage: FunctionComponent<ContentPageProps> = ({
	breadcrumbs = [],
	title = undefined,
	titleComponent = undefined,
	backLink = undefined,
	editLink = undefined,
	closeLink = undefined,
	settingsLink = undefined,
	filterLink = undefined,
	loading,
	error,
	children
}) => {

	const showIcons = editLink || closeLink || settingsLink || filterLink;
	const showHeader = showIcons || title || backLink;
	return (
		<>
			{showHeader && (
				<ContentPageHeader
					breadcrumbs={breadcrumbs}
					title={title}
					titleComponent={titleComponent}
					backLink={backLink}
					editLink={editLink}
					closeLink={closeLink}
					settingsLink={settingsLink}
					filterLink={filterLink}
				/>
			)}
			<ContentLoader loading={loading} error={error}>
				{children}
			</ContentLoader>
		</>
	);
};

export default ContentPage;
