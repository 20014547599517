import { FunctionComponent, ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { setNavbarOpen } from "store/reducers/layout/layoutReducer";
import AppHeader from "./AppHeader";

type LayoutProps = {
	children: ReactNode;
};

const Layout: FunctionComponent<LayoutProps> = ({
	children
}) => {
	const dispatch = useAppDispatch();
	const isNavbarOpen = useAppSelector(
		(state) => state.layout.isNavbarOpen
	);

	return (
		<>
			<AppHeader
				onToggleNavigation={() => dispatch(setNavbarOpen(!isNavbarOpen))}
			/>
			<div style={{ padding: "10px 8px" }}>
				{children}
			</div>
		</>
	);
};

export default Layout;
