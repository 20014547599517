import { Route, Routes, useParams } from "react-router-dom";
import ProtectedRoute from "components/common/ProtectedRoute/ProtectedRoute";
import DishForm from "components/composed/DishesPage/DishForm/DishForm";
import TagPage from "components/composed/TagPage/TagPage";
import TagForm from "components/composed/TagPage/TagForm/TagForm";
import WorkspaceForm from "components/composed/WorkspacesPage/WorkspaceForm/WorkspaceForm";
import DishView from "components/composed/DishesPage/DishView/DishView";
import SharePage from "components/composed/SharePage/SharePage";
import WorkspacePage from "components/composed/WorkspacesPage/WorkspacePage/WorkspacePage";
import WorkspaceLayout from "./WorkspacePage/WorkspaceLayout";
import GeneratorFilterForm from "../GeneratorPage/GeneratorForm/GeneratorFilterForm";
import { pages } from "pages";
import ContentError from "components/common/ContentPage/ContentError";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { FunctionComponent } from "react";
import { getNumberParam } from "helpers/views";


type WorkspaceLayoutParams = {
	workspaceId?: string;
};

const Workspace: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
    const params = useParams<WorkspaceLayoutParams>();
	const [ workspaceId, paramError ] = getNumberParam(params.workspaceId, "workspaceId", intl);

	if (paramError || !workspaceId) {
        return <ContentError error={paramError} />
    }

    return (
        <WorkspaceLayout workspaceId={workspaceId} >
            <Routes>
                <Route path={pages.workspace.edit.pattern} element={
                    <ProtectedRoute>
                        <WorkspaceForm workspaceId={workspaceId} />
                    </ProtectedRoute>
                } />
                <Route path={pages.workspace.share.pattern} element={
                    <ProtectedRoute>
                        <SharePage workspaceId={workspaceId} />
                    </ProtectedRoute>
                } />
                <Route path={pages.workspace.tags.pattern} element={
                    <ProtectedRoute>
                        <TagPage workspaceId={workspaceId} />
                    </ProtectedRoute>
                } />
                <Route path={pages.workspace.tagCreate.pattern} element={
                    <ProtectedRoute>
                        <TagForm workspaceId={workspaceId} />
                    </ProtectedRoute>
                } />
                <Route path={pages.workspace.tagEdit.pattern} element={
                    <ProtectedRoute>
                        <TagForm workspaceId={workspaceId} />
                    </ProtectedRoute>
                } />
                <Route path={pages.workspace.dishCreate.pattern} element={
                    <ProtectedRoute>
                        <DishForm workspaceId={workspaceId} />
                    </ProtectedRoute>
                } />
                <Route path={pages.workspace.dishEdit.pattern} element={
                    <ProtectedRoute>
                        <DishForm workspaceId={workspaceId} />
                    </ProtectedRoute>
                } />
                <Route path={pages.workspace.dishView.pattern} element={
                    <ProtectedRoute>
                        <DishView workspaceId={workspaceId} />
                    </ProtectedRoute>
                } />
                <Route path={pages.workspace.suggestedSettings.pattern} element={
                    <ProtectedRoute>
                        <GeneratorFilterForm workspaceId={workspaceId} />
                    </ProtectedRoute>
                } />
                <Route path="*" element={
                    <ProtectedRoute>
                        <WorkspacePage workspaceId={workspaceId} />
                    </ProtectedRoute>
                } />
            </Routes>
        </WorkspaceLayout>
	);
};

export default injectIntl(Workspace);