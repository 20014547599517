import { useState, useEffect, FunctionComponent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import {
	injectIntl,
	WrappedComponentProps
} from "react-intl";
import ListHeaderSection from "components/common/ListHeaderSection/ListHeaderSection";
import { deleteWorkspace, fetchWorkspaces, selectWorkspaceDeleteError, selectWorkspaceDeleteStatus, selectWorkspaces, selectWorkspacesFetchError, selectWorkspacesFetchStatus } from "store/reducers/workspaces/workspacesReducer";
import { Workspace } from "api/Workspaces/Types";
import { useAppDispatch, useAppSelector } from "hooks";
import { pages } from "pages";
import WorkspaceDeleteDialog from "./WorkspaceDeleteDialog";
import { StoreStatus } from "store/common";
import ContentLoader from "components/common/ContentPage/ContentLoader";


const WorkspacesPage: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [isDialogOpened, setDialogOpened] = useState(false);
	const [workspaceToDelete, setWorkspaceToDelete] =
		useState<Workspace | null>(null);

	const workspaces = useAppSelector(selectWorkspaces);
	const fetchStatus = useAppSelector(selectWorkspacesFetchStatus);
	const fetchError = useAppSelector(selectWorkspacesFetchError);

	const deleteStatus = useAppSelector(selectWorkspaceDeleteStatus);
	const deleteError = useAppSelector(selectWorkspaceDeleteError);


	const onWorkspaceClick = async (workspaceId: number) => {
		navigate(pages.workspace.url(workspaceId));
	};

	useEffect(() => {
		if (fetchStatus === StoreStatus.Idle) {
			dispatch(fetchWorkspaces());
		}
	}, [ fetchStatus, dispatch ]);

	const renderListItems = () => {
		if (workspaces.length > 0) {
			return (
				<>
					{workspaces.map((workspace: Workspace) => {
						return (
							<ListItem key={workspace.id} divider>
								<ListItemText
									primary={workspace.name}
									onClick={() => onWorkspaceClick(workspace.id)}
									style={{
										cursor: "pointer",
										flex: "inherit",
										wordBreak: "break-word"
									}}
								/>

								<ListItemSecondaryAction>
									{!workspace.personal && (
										<>
											<IconButton
												component={Link}
												to={pages.workspace.share.url(workspace.id)}
											>
											<ShareIcon color="primary" />
												</IconButton>
											<IconButton
												component={Link}
												to={pages.workspace.edit.url(workspace.id)}
											>
												<EditIcon color="primary" />
											</IconButton>
											<IconButton
												onClick={() => {
													setWorkspaceToDelete(workspace);
													setDialogOpened(true);
												}}
											>
												<DeleteIcon color="secondary" />
											</IconButton>
										</>
									)}
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</>
			);
		} else {
			return (
				<Typography variant="h5">
					{intl.formatMessage({ id: "app.emptyWorkspaces" })}
				</Typography>
			);
		}
	};

	const onDeleteWorkspaceClick = async () => {
		if (workspaceToDelete) {
			dispatch(deleteWorkspace(workspaceToDelete.id));
		}
	};

	useEffect(() => {
		if (deleteStatus === StoreStatus.Succeeded || deleteStatus === StoreStatus.Failed) {
			closeDialog();
		}
		if (deleteStatus === StoreStatus.Failed) {
			if (deleteError?.message) {
				toast.error(deleteError.message);
			} else {
				toast.error(
					`☠️ ${intl.formatMessage({ id: "app.somethingWentWrong" })}`
				);
			}
		}
	}, [ deleteError, deleteStatus, intl ]);

	const closeDialog = () => {
		setDialogOpened(false);
	};

	return (
		<div>
			<ListHeaderSection
				pageTitle={intl.formatMessage({ id: "app.workspaces" }) + ":"}
				btnHref={pages.workspaceCreate.url()}
				btnText={intl.formatMessage({ id: "app.create" })}
			/>
			<ContentLoader
				error={fetchError?.message}
				loading={fetchStatus === StoreStatus.InProgress}
			>
				<List>{renderListItems()}</List>
				{workspaceToDelete && 
					(<WorkspaceDeleteDialog 
						isOpen={isDialogOpened}
						deleteInProgress={deleteStatus === StoreStatus.InProgress}
						onClose={closeDialog}
						workspace={workspaceToDelete}
						onDelete={onDeleteWorkspaceClick}
					/>)}
			</ContentLoader>
		</div>
	);
};

export default injectIntl(WorkspacesPage);
