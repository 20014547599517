import { FunctionComponent } from "react";
import { useAppSelector } from "hooks";
import {
	Toolbar,
	Drawer,
	Hidden
} from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import FoodBankIcon from '@mui/icons-material/FoodBank';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { injectIntl, WrappedComponentProps } from "react-intl";

import MobileNavigation from "./MobileNavigation";
import SideNavigation from "./SideNavigation";
import { pages } from "pages";

type NavigationProps = {
    open: boolean;
	workspaceId: number;
    sideNavigationWidth: number;
    mobileNavigationHeight: number;
}

const Navigation: FunctionComponent<NavigationProps & WrappedComponentProps> = ({
	intl,
    open,
	workspaceId,
    sideNavigationWidth,
    mobileNavigationHeight,
}) => {
	const isAuthenticated = useAppSelector(
		(state) => state.auth.isAuthenticated
	);

	const dataForNavigation = [
		{
			icon: <FoodBankIcon style={{ color: "#000" }} />,
			href: pages.workspace.url(workspaceId),
			text: intl.formatMessage({ id: "app.dishes" })
		},
		{
			icon: <LabelIcon style={{ color: "#000" }} />,
			href: pages.workspace.tags.url(workspaceId),
			text: intl.formatMessage({ id: "app.tags" })
		},
		{
			icon: <WorkspacesIcon style={{ color: "#000" }} />,
			href: pages.workspaces.url(),
			text: intl.formatMessage({ id: "app.workspaces" })
		}
	];

	return (
		<>
			<Hidden smDown>
				{isAuthenticated && (
					<Drawer
						sx={{ flexShrink: 0 }}
						PaperProps={{
							sx: {
								width: sideNavigationWidth
							}
						}}
						anchor="left"
						variant="persistent"
						elevation={1}
						open={open}
					>
						<Toolbar />
						<SideNavigation listItems={dataForNavigation} />
					</Drawer>
				)}
			</Hidden>

			{isAuthenticated && (<Hidden smUp>
				<MobileNavigation listItems={dataForNavigation} height={mobileNavigationHeight} />
			</Hidden>)}
        </>
	);
};

export default injectIntl(Navigation);
