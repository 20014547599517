import {
  ShareLink,
  ShareLinkCreate,
  ShareLinks,
  ShareLinkUpdate,
} from "./Types";
import { AxiosInstance } from "axios";
import { ApiDataResponse, ApiResponse } from "../Types";
import { handle, handleData } from "../handlers";

export class ShareLinkClient {
  axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async getShareLinks(
    workspaceId: number
  ): Promise<ApiDataResponse<ShareLinks>> {
    return await handleData<ShareLinks>(() =>
      this.axios.get(`/api/v1/workspaces/${workspaceId}/share_links`)
    );
  }

  async getShareLink(
    workspaceId: number,
    shareLinkId: number
  ): Promise<ApiDataResponse<ShareLink>> {
    return await handleData<ShareLink>(() =>
      this.axios.get(
        `/api/v1/workspaces/${workspaceId}/share_links/${shareLinkId}`
      )
    );
  }

  async createShareLink(
    workspaceId: number,
    shareLink: ShareLinkCreate
  ): Promise<ApiDataResponse<ShareLink>> {
    return await handleData<ShareLink>(() =>
      this.axios.post(
        `/api/v1/workspaces/${workspaceId}/share_links`,
        shareLink
      )
    );
  }

  async updateShareLink(
    workspaceId: number,
    shareLinkId: number,
    shareLink: ShareLinkUpdate
  ): Promise<ApiDataResponse<ShareLink>> {
    return await handleData<ShareLink>(() =>
      this.axios.patch(
        `/api/v1/workspaces/${workspaceId}/share_links/${shareLinkId}`,
        shareLink
      )
    );
  }

  async deleteShareLink(
    workspaceId: number,
    shareLinkId: number
  ): Promise<ApiResponse> {
    return await handle(() =>
      this.axios.delete(
        `/api/v1/workspaces/${workspaceId}/share_links/${shareLinkId}`
      )
    );
  }
}
