import { FunctionComponent, ReactNode } from "react";
import { Link } from "react-router-dom";
import {
	IconButton,
	Typography,
	Divider,
	Breadcrumbs
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import { TitleWrapper } from "theme/styledComponents";

export type Breadcrumb = {
	label: string;
	url: string;
}

type ContentPageHeaderProps = {
	breadcrumbs?: Breadcrumb[];
	title?: string;
	titleComponent?: ReactNode;
	backLink?: string;
	editLink?: string;
	closeLink?: string;
	settingsLink?: string;
	filterLink?: string;
};

const ContentPageHeader: FunctionComponent<ContentPageHeaderProps> = ({
	breadcrumbs = [],
	title = undefined,
	titleComponent = undefined,
	backLink = undefined,
	editLink = undefined,
	closeLink = undefined,
	settingsLink = undefined,
	filterLink = undefined
}) => {

	const showIcons = editLink || closeLink || settingsLink || filterLink;
	return (
		<>
			{breadcrumbs.length > 0 && (
				<Breadcrumbs separator=">" >
					{breadcrumbs.map(b => (
						<Link key={b.url} to={b.url}>
							{b.label}
						</Link>
					))}
				</Breadcrumbs>
			)}
			<TitleWrapper>
				{backLink && (
					<IconButton component={Link} to={backLink}>
						<ArrowBackIcon />
					</IconButton>
				)}
				{!titleComponent && title && (
					<Typography variant="h5">{title}</Typography>
				)}
				{titleComponent && (
					<>{titleComponent}</>
				)}
				{showIcons && (
					<div style={{
						marginLeft: 'auto'
					}}>
						{editLink && (
						<IconButton component={Link} to={editLink}>
							<EditIcon />
						</IconButton>
						)}
						{filterLink && (
						<IconButton component={Link} to={filterLink}>
							<FilterAltIcon />
						</IconButton>
						)}
						{settingsLink && (
						<IconButton component={Link} to={settingsLink}>
							<SettingsIcon />
						</IconButton>
						)}
						{closeLink && (
						<IconButton component={Link} to={closeLink}>
							<CloseIcon />
						</IconButton>
						)}
					</div>
				)}
			</TitleWrapper>
			<Divider />
		</>
	);
};

export default ContentPageHeader;
