import {
	createAsyncThunk,
	createSlice
} from "@reduxjs/toolkit";
import { Share, ShareCreate, Shares, ShareUpdate } from "api/Shares/Types";
import api from "api";
import { extractErrors, StoreError, StoreStatus } from "store/common";
import { RootState } from "store/store";
import { Invitation, Invitations, InvitationUpdate } from "api/Invitations/Types";

interface SharesState {
	shares: Shares;
	fetchSharesStatus: StoreStatus;
	fetchSharesError?: StoreError;
	updateShareStatus: StoreStatus;
	updateShareError?: StoreError;
	deleteShareStatus: StoreStatus;
	deleteShareError?: StoreError;
	invitations: Invitations;
	fetchInvitationsStatus: StoreStatus;
	fetchInvitationsError?: StoreError;
	updateInvitationStatus: StoreStatus;
	updateInvitationError?: StoreError;
	deleteInvitationStatus: StoreStatus;
	deleteInvitationError?: StoreError;
}

const initialState: SharesState = {
	shares: [],
	fetchSharesStatus: StoreStatus.Idle,
  	fetchSharesError: undefined,
	updateShareStatus: StoreStatus.Idle,
	updateShareError: undefined,
	deleteShareStatus: StoreStatus.Idle,
	deleteShareError: undefined,
	invitations: [],
	fetchInvitationsStatus: StoreStatus.Idle,
  	fetchInvitationsError: undefined,
	updateInvitationStatus: StoreStatus.Idle,
	updateInvitationError: undefined,
	deleteInvitationStatus: StoreStatus.Idle,
	deleteInvitationError: undefined,
};

export const selectShares = (state: RootState): Shares => state.shares.shares;
export const selectShare = (state: RootState, workspaceId?: number): Share | null =>
	state.shares.shares.find((workspace: Share) => workspace.id === workspaceId);
export const selectSharesFetchError = (state: RootState): StoreError | undefined => state.shares.fetchSharesError;
export const selectSharesFetchStatus = (state: RootState): StoreError | undefined => state.shares.fetchSharesStatus;
export const selectShareUpdateError = (state: RootState): StoreError | undefined => state.shares.updateShareError;
export const selectShareUpdateStatus = (state: RootState): StoreStatus => state.shares.updateShareStatus;
export const selectShareDeleteError = (state: RootState): StoreError | undefined => state.shares.deleteShareError;
export const selectShareDeleteStatus = (state: RootState): StoreStatus => state.shares.deleteShareStatus;

export const selectInvitations = (state: RootState): Invitations => state.shares.invitations;
export const selectInvitation = (state: RootState, workspaceId?: number): Invitation | null =>
	state.shares.shares.find((workspace: Invitation) => workspace.id === workspaceId);
export const selectInvitationsFetchError = (state: RootState): StoreError | undefined => state.shares.fetchInvitationsError;
export const selectInvitationsFetchStatus = (state: RootState): StoreStatus => state.shares.fetchInvitationsStatus;
export const selectInvitationUpdateError = (state: RootState): StoreError | undefined => state.shares.updateInvitationError;
export const selectInvitationUpdateStatus = (state: RootState): StoreStatus => state.shares.updateInvitationStatus;
export const selectInvitationDeleteError = (state: RootState): StoreError | undefined => state.shares.deleteInvitationError;
export const selectInvitationDeleteStatus = (state: RootState): StoreStatus => state.shares.deleteInvitationStatus;

function updateShares(current: Shares, update: Shares): Shares {
	update.forEach(share => {
		const shareIndex = current.findIndex(s => s.id === share.id);
		if (shareIndex >= 0) {
			current[shareIndex] = share;
		} else {
			current.push(share);
		}
	});

	return current;
}

function updateInvitations(current: Invitations, update: Invitations): Invitations {
	update.forEach(invitation => {
		const invitationIndex = current.findIndex(i => i.id === invitation.id);
		if (invitationIndex >= 0) {
			current[invitationIndex] = invitation;
		} else {
			current.push(invitation);
		}
	});

	return current;
}

export const sharesSlice = createSlice({
	name: "shares",
	initialState,
	reducers: {
		resetUpdateShare: (state) => {
			state.updateShareStatus = StoreStatus.Idle;
			state.updateShareError = undefined;
		},
		resetUpdateInvitation: (state) => {
			state.updateInvitationStatus = StoreStatus.Idle;
			state.updateInvitationError = undefined;
		}
	},
	extraReducers(builder) {
	  builder
		.addCase(fetchShares.pending, (state) => {
		  state.fetchSharesStatus = StoreStatus.InProgress;
		})
		.addCase(fetchShares.fulfilled, (state, action) => {
		  state.fetchSharesStatus = StoreStatus.Succeeded;
		  if (action.payload) {
			state.shares = updateShares(state.shares, action.payload);
		  }
		})
		.addCase(fetchShares.rejected, (state, action) => {
		  state.fetchSharesStatus = StoreStatus.Failed
		  state.fetchSharesError = extractErrors(action)
		})
		.addCase(fetchShare.pending, (state) => {
		  state.fetchSharesStatus = StoreStatus.InProgress;
		})
		.addCase(fetchShare.fulfilled, (state, action) => {
		  state.fetchSharesStatus = StoreStatus.Succeeded;
		  if (action.payload) {
			if (action.payload) {
				state.shares = updateShares(state.shares, [ action.payload ]);
			}
		  }
		})
		.addCase(fetchShare.rejected, (state, action) => {
		  state.fetchSharesStatus = StoreStatus.Failed
		  state.fetchSharesError = extractErrors(action)
		})
		.addCase(createShare.pending, (state) => {
		  state.updateShareStatus = StoreStatus.InProgress;
		})
		.addCase(createShare.fulfilled, (state, action) => {
		  state.updateShareStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			if (action.payload) {
				state.shares = updateShares(state.shares, [ action.payload ]);
			}
		  }
		})
		.addCase(createShare.rejected, (state, action) => {
		  state.updateShareStatus = StoreStatus.Failed
		  state.updateShareError = extractErrors(action)
		})
		.addCase(updateShare.pending, (state) => {
		  state.updateShareStatus = StoreStatus.InProgress;
		})
		.addCase(updateShare.fulfilled, (state, action) => {
		  state.updateShareStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			if (action.payload) {
				state.shares = updateShares(state.shares, [ action.payload ]);
			}
		  }
		})
		.addCase(updateShare.rejected, (state, action) => {
		  state.updateShareStatus = StoreStatus.Failed
		  state.updateShareError = extractErrors(action)
		})
		.addCase(deleteShare.pending, (state) => {
		  state.updateShareStatus = StoreStatus.InProgress;
		})
		.addCase(deleteShare.fulfilled, (state, action) => {
		  state.deleteShareStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
		  	state.shares = state.shares.filter((share) => share.id !== action.payload);
		  }
		})
		.addCase(deleteShare.rejected, (state, action) => {
		  state.updateShareStatus = StoreStatus.Failed
		  state.updateShareError = extractErrors(action)
		})
		.addCase(fetchInvitations.pending, (state) => {
		  state.fetchInvitationsStatus = StoreStatus.InProgress;
		})
		.addCase(fetchInvitations.fulfilled, (state, action) => {
		  state.fetchInvitationsStatus = StoreStatus.Succeeded;
		  if (action.payload) {
			state.invitations = updateInvitations(state.invitations, action.payload);
		  }
		})
		.addCase(fetchInvitations.rejected, (state, action) => {
		  state.fetchInvitationsStatus = StoreStatus.Failed
		  state.fetchInvitationsError = extractErrors(action)
		})
		.addCase(updateInvitation.pending, (state) => {
		  state.updateInvitationStatus = StoreStatus.InProgress;
		})
		.addCase(updateInvitation.fulfilled, (state, action) => {
		  state.updateInvitationStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
			if (action.payload) {
				state.invitations = updateInvitations(state.invitations, [ action.payload ]);
			}
		  }
		})
		.addCase(updateInvitation.rejected, (state, action) => {
		  state.updateInvitationStatus = StoreStatus.Failed
		  state.updateInvitationError = extractErrors(action)
		})
		.addCase(deleteInvitation.pending, (state) => {
		  state.updateInvitationStatus = StoreStatus.InProgress;
		})
		.addCase(deleteInvitation.fulfilled, (state, action) => {
		  state.updateInvitationStatus = StoreStatus.Succeeded;
		  if (action.payload) { 
		  	state.invitations = state.invitations.filter((invitation) => invitation.id !== action.payload);
		  }
		})
		.addCase(deleteInvitation.rejected, (state, action) => {
		  state.updateInvitationStatus = StoreStatus.Failed
		  state.updateInvitationError = extractErrors(action)
		})
	}
});

export const fetchShares = createAsyncThunk('shares/fetchShares', async (workspaceId: number, { rejectWithValue }) => {
	try {
		const resp = await api.shares.getShares(workspaceId);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface ShareParams {
	workspaceId: number;
	shareId: number;
}

export const fetchShare = createAsyncThunk('shares/fetchShare', async (params: ShareParams, { rejectWithValue }) => {
	try {
		const { workspaceId, shareId } = params; 
		const resp = await api.shares.getShare(workspaceId, shareId);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface CreateShareParams {
	workspaceId: number,
	data: ShareCreate
}

export const createShare = createAsyncThunk('shares/createShare', async (params: CreateShareParams, { rejectWithValue }) => {
	try {
		const { workspaceId, data } = params;
		const resp = await api.shares.createShare(workspaceId, data);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface UpdateShareParams {
	workspaceId: number,
	shareId: number,
	data: ShareUpdate
}

export const updateShare = createAsyncThunk('shares/updateShare', async (params: UpdateShareParams, { rejectWithValue }) => {
	try {
		const { workspaceId, shareId, data } = params;
		const resp = await api.shares.updateShare(workspaceId, shareId, data);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const deleteShare = createAsyncThunk('shares/deleteShare', async (params: ShareParams, { rejectWithValue }) => {
	try {
		const { workspaceId, shareId } = params;
		await api.shares.deleteShare(workspaceId, shareId);
		return shareId;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const fetchInvitations = createAsyncThunk('invitations/fetchInvitations', async (workspaceId: number, { rejectWithValue } ) => {
	try {
		const resp = await api.invitations.getInvitations(workspaceId);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

interface InvitationParams {
	workspaceId: number;
	invitationId: number;
}

interface UpdateInvitationParams {
	workspaceId: number,
	invitationId: number,
	data: InvitationUpdate
}

export const updateInvitation = createAsyncThunk('invitations/updateInvitation', async (params: UpdateInvitationParams, { rejectWithValue }) => {
	try {
		const { workspaceId, invitationId, data } = params;
		const resp = await api.invitations.updateInvitation(workspaceId, invitationId, data);
		return resp.data;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const deleteInvitation = createAsyncThunk('invitations/deleteInvitation', async (params: InvitationParams, { rejectWithValue }) => {
	try {
		const { workspaceId, invitationId } = params;
		await api.invitations.deleteInvitation(workspaceId, invitationId);
		return invitationId;
	} catch (err) {
		console.log(err);
		return rejectWithValue(err);
	}
})

export const { resetUpdateShare, resetUpdateInvitation } = sharesSlice.actions;

const sharesReducer = sharesSlice.reducer;

export default sharesReducer;
