import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { Link, useNavigate } from "react-router-dom";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { ButtonsWrapper } from "theme/styledComponents";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Button,
	FormLabel,
	TextField
} from "@mui/material";
import ContentPage from "components/common/ContentPage/ContentPage";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { prepareTagsArrForGenerator, tagsToFormControls } from "helpers/tags";
import { resetFetchStatus, selectQuery, setQuery } from "store/reducers/generator/generatorReducer";
import { GenerateQuery } from "api/Generator/Types";
import { pages } from "pages";
import TagValuePicker from "./TagValuePicker";
import { ImpactValue, TimeEffort } from "api/Types";
import { selectTags } from "store/reducers/tags/tagsReducer";
import { WorkspaceContextComponentProps } from "components/common/WorkspaceContext";


const GeneratorFilterForm: FunctionComponent<WorkspaceContextComponentProps & WrappedComponentProps> = ({ workspaceId, intl }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const closeLink = pages.workspace.suggested.url(workspaceId);

	const tags = useAppSelector(selectTags);
	const query = useAppSelector(selectQuery);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({ 
		shouldUnregister: true,
		defaultValues: query
	});

	const onSubmit = async (formData: FieldValues) => {
		const dataToSend: GenerateQuery = {
			time_effort: formData.time_effort,
			season: Number(formData.season),
			tags: prepareTagsArrForGenerator(formData)
		};

		if (formData.kilocalories) {
			dataToSend.kilocalories = Number(formData.kilocalories);
		}

		dispatch(setQuery(dataToSend));
		dispatch(resetFetchStatus());
		navigate(closeLink);
	};

	const breadcrumbs = [
		{
			label: intl.formatMessage({ id: "app.suggested" }),
			url: pages.workspace.url(workspaceId),
		}
	]

	return (
		<ContentPage
			breadcrumbs={breadcrumbs}
			title={intl.formatMessage({ id: "app.suggestions_settings" })}
			closeLink={closeLink}
			loading={!tags}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormControl
					fullWidth
					error={!!errors.time_effort}
					style={{ marginTop: "0.8rem" }}
				>
					<InputLabel>
						{intl.formatMessage({ id: "app.time_effort" })}
					</InputLabel>
					<Controller
						name="time_effort"
						control={control}
						render={({ field }) => (
							<Select
								label={intl.formatMessage({
									id: "app.time_effort"
								})}
								style={{ textAlign: "left" }}								
								{...field}
							>
								<MenuItem value={TimeEffort.Short}>
									{intl.formatMessage({
										id: "app.short"
									})}
								</MenuItem>
								<MenuItem value={TimeEffort.Medium}>
									{intl.formatMessage({
										id: "app.medium"
									})}
								</MenuItem>
								<MenuItem value={TimeEffort.Long}>
									{intl.formatMessage({ id: "app.long" })}
								</MenuItem>
							</Select>
						)}
						
						rules={{ required: true }}
						defaultValue={TimeEffort.Long}
					/>
					<FormHelperText>
						{errors.time_effort &&
							intl.formatMessage({ id: "app.emptyField" })}
					</FormHelperText>
				</FormControl>

				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.kilocalories" })}
					{...register("kilocalories")}
					style={{ marginTop: "0.8rem" }}
					type="number"
					inputProps={{ min: "0", max: "10000", step: "1" }}
				/>

				<FormControl
					fullWidth
					component="fieldset"
					error={!!errors.season}
					style={{ marginTop: "1.5rem" }}
				>
					<FormLabel component="legend">
						{intl.formatMessage({ id: "app.season" })}
					</FormLabel>
					<Controller
						rules={{ required: true }}
						control={control}
						name="season"
						defaultValue={ImpactValue.Ignore}
						render={({ field }) => (
							<TagValuePicker {...field} />
						)}
					/>
					<FormHelperText>
						{errors.season &&
							intl.formatMessage({
								id: "app.radioRequired"
							})}
					</FormHelperText>
				</FormControl>

				{tags &&
					tagsToFormControls(tags).map((group) => (
						<FormControl
							fullWidth
							aria-label="position"
							key={group.groupName}
							style={{ margin: "0 auto" }}
						>
							<FormLabel
								style={{
									textAlign: "center",
									marginTop: "0.8rem"
								}}
							>
								{group.groupName}
							</FormLabel>
							{group.values.map((tagValue, index) => {
								console.log(index);
								console.log(tagValue.id);
								
								return (
									<FormControl
										component="fieldset"
										// error={!!errors.tags[index]}
										key={tagValue.id}
										style={{ marginTop: "0.8rem" }}
									>
										<FormLabel component="legend">
											{tagValue.name}
										</FormLabel>
										<Controller
											rules={{ required: true }}
											control={control}
											name={`tags.${index}.id`}
											defaultValue={ImpactValue.Ignore}
											render={({ field }) => (
												<TagValuePicker {...field} />
											)}
										/>
										<FormHelperText>
											{/* {errors.tags?[index] &&
												intl.formatMessage({
													id: "app.radioRequired"
												})} */}
										</FormHelperText>
									</FormControl>
								);
							})}
						</FormControl>
					))}

				<ButtonsWrapper>
					<Button
						color="secondary"
						variant="contained"
						component={Link}
						to={closeLink}
						style={{ marginRight: 10 }}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<Button color="primary" variant="contained" type="submit">
						{intl.formatMessage({ id: "app.save" })}
					</Button>
				</ButtonsWrapper>
			</form>
		</ContentPage>
	);
};

export default injectIntl(GeneratorFilterForm);
