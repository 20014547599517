import { FunctionComponent } from "react";
import {
	Button,
	CircularProgress,
} from "@mui/material";

interface DialogButtonProps {
	caption: string;
	inProgress: boolean;
	onClick: () => void;
}

const DialogButton: FunctionComponent<DialogButtonProps> = ({
	caption,
	inProgress,
	onClick
}) => {
	if (inProgress) {
		return <Button>
			<CircularProgress size={23} color="inherit" />
		</Button>
	}

	return <Button color="secondary" onClick={onClick}>
		{caption}
	</Button>
};

export default DialogButton;
