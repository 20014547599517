import axios, { AxiosInstance } from "axios";
import { WorkspaceClient } from "./Workspaces/Workspace";
import {
  ApiDataResponse,
  Healthcheck,
  Credentials,
  JwtToken,
  ApiResponse,
  Token,
  TokenStorage,
} from "./Types";
import { UserClient } from "./Users/User";
import { ApiError, handleData } from "./handlers";
import { DishClient } from "./Dishes/Dish";
import { GeneratorClient } from "./Generator/Generator";
import { InvitationClient } from "./Invitations/Invitation";
import { ShareClient } from "./Shares/Share";
import { ShareLinkClient } from "./ShareLinks/ShareLink";
import { TagsClient } from "./Tags/Tags";
import jwt_decode from "jwt-decode";
export const AuthTokenType = "auth";
export const RefreshTokenType = "refresh";

export type TokenType = "auth" | "refresh";

interface TokenData {
  iat: number;
  exp: number;
  roles: string[];
  username: string;
}

const loginUrl = "/api/v1/login";
const refreshUrl = `/api/v1/token/refresh`;

const DefaultTokenStorage: TokenStorage = {
  getToken: () => null,
  setToken: () => {},
  removeToken: () => {},
};

export interface SpicifyApiInterface {
  get dishes(): DishClient;
  get generator(): GeneratorClient;
  get invitations(): InvitationClient;
  get shares(): ShareClient;
  get shareLinks(): ShareLinkClient;
  get tags(): TagsClient;
  get users(): UserClient;
  get workspaces(): WorkspaceClient;

  login(credentials: Credentials): Promise<ApiResponse>;
  rebuild_session(): void;
  logout(): void;
  session(): Token | null;
  refresh(): Promise<ApiResponse>;
  healthcheck(): Promise<ApiDataResponse<Healthcheck>>;
}

export interface SpicifyApiStatic extends SpicifyApiInterface {
  isApiError(payload: any): payload is ApiError; 
}

export class SpicifyApi implements SpicifyApiInterface {
  private axios: AxiosInstance;
  private storage: TokenStorage;
  private onSessionCreated: () => void;
  private onSessionDestroyed: () => void;
  private onMissingSession: () => void;

  private readonly _dishes: DishClient;
  private readonly _generator: GeneratorClient;
  private readonly _invitations: InvitationClient;
  private readonly _shares: ShareClient;
  private readonly _shareLinks: ShareLinkClient;
  private readonly _tags: TagsClient;
  private readonly _users: UserClient;
  private readonly _workspaces: WorkspaceClient;

  constructor(
    baseUrl: string = "",
    storage: TokenStorage = DefaultTokenStorage,
    onSessionCreated: () => void = () => {},
    onSessionDestroyed: () => void = () => {},
    onMissingSession: () => void = () => {}
  ) {
    this.storage = storage;
    this.onSessionCreated = onSessionCreated;
    this.onSessionDestroyed = onSessionDestroyed;
    this.onMissingSession = onMissingSession;

    this.axios = axios.create({
      baseURL: baseUrl,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });

    this._dishes = new DishClient(this.axios);
    this._generator = new GeneratorClient(this.axios);
    this._invitations = new InvitationClient(this.axios);
    this._shares = new ShareClient(this.axios);
    this._shareLinks = new ShareLinkClient(this.axios);
    this._tags = new TagsClient(this.axios);
    this._users = new UserClient(this.axios);
    this._workspaces = new WorkspaceClient(this.axios);

    this.axios.interceptors.request.use(
      async (config) => {
        const isLogin = config.url === loginUrl;
        const isRefresh = config.url === refreshUrl;

        if (!isLogin && !isRefresh) {
          const token = this.storage.getToken(AuthTokenType);
          if (token) {
            config.headers!["Authorization"] = `Bearer ${token}`;
          }
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        const isStatus401 = error.response?.status === 401;
        const isRetry = originalRequest?._retry || false;
        const isLogin = originalRequest?.url === loginUrl;
        const isRefresh = originalRequest?.url === refreshUrl;

        if (isStatus401 && isRetry) {
          this.sessionExpired();
        } else if (isStatus401 && !isRetry && !isLogin && !isRefresh) {
          originalRequest._retry = true;

          let result = await this.refresh();
          if (result.error) {
            this.sessionExpired();

            return Promise.reject(error);
          }

          return this.axios(originalRequest);
        }

        return Promise.reject(error);
      }
    );
  }

  private sessionExpired() {
    this.logout();
    this.onMissingSession();
  }

  private getDecodedToken(type: TokenType): Token | null {
    const token = this.storage.getToken(type);
    if (token) {
      const token_data = jwt_decode<TokenData>(token);
      return new Token(
        new Date(token_data.iat * 1000),
        new Date(token_data.exp * 1000),
        token_data.roles,
        token_data.username
      );
    }

    return null;
  }

  private storeTokens(jwtToken: ApiDataResponse<JwtToken>): ApiResponse {
    if (jwtToken.status === 200 && jwtToken.data) {
      this.storage.setToken(AuthTokenType, jwtToken.data.token);
      this.storage.setToken(RefreshTokenType, jwtToken.data.refresh_token);
    }
    return new ApiResponse(jwtToken.status, jwtToken.error);
  }

  public get dishes() {
    return this._dishes;
  }
  public get generator() {
    return this._generator;
  }
  public get invitations() {
    return this._invitations;
  }
  public get shares() {
    return this._shares;
  }
  public get shareLinks() {
    return this._shareLinks;
  }
  public get tags() {
    return this._tags;
  }
  public get users() {
    return this._users;
  }
  public get workspaces() {
    return this._workspaces;
  }

  public async login(credentials: Credentials): Promise<ApiResponse> {
    const resp = this.storeTokens(
      await handleData<JwtToken>(() => {
        return this.axios.post(loginUrl, credentials);
      })
    );
    this.onSessionCreated();

    return resp;
  }

  public rebuild_session(): void {
    const token = this.getDecodedToken(AuthTokenType);

    if (!token || token.isExpired) {
      this.onMissingSession();
      return;
    }

    this.refresh();
    this.onSessionCreated();
  }

  public logout() {
    if (this.onSessionDestroyed) this.onSessionDestroyed();

    this.storage.removeToken(AuthTokenType);
    this.storage.removeToken(RefreshTokenType);
  }

  public session(): Token | null {
    return this.getDecodedToken(AuthTokenType);
  }

  public async refresh(): Promise<ApiResponse> {
    const refresh_token = this.storage.getToken(RefreshTokenType);

    if (!refresh_token) {
      return new ApiResponse(401, {
        code: 401,
        message: "sdk.missing_token",
        context: null,
      });
    }

    return this.storeTokens(
      await handleData<JwtToken>(() => {
        return this.axios.post(refreshUrl, { refresh_token });
      })
    );
  }

  public async healthcheck(): Promise<ApiDataResponse<Healthcheck>> {
    return await handleData<Healthcheck>(() =>
      this.axios.get(`/api/v1/healthcheck`)
    );
  }
}
